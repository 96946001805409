<template>
    <div id="app">
        <div class="comptaconex">
            <h1>Connexion Comptable</h1>
        </div>
        <form @submit.prevent="login">
            <fieldset>
                <legend class="titre_field">Votre Email</legend>
                <input type="email" class="input_conex" v-model="user.email" placeholder="Votre Email">
            </fieldset>
            <fieldset class="mdp">
                <legend class="titre_field">Mot de passe</legend>
                <input type="password" class="input_conex" v-model="user.password" placeholder="Mot de passe">
            </fieldset>
            <div class="">
                <button class="btnconnex" type="submit">Se connecter</button>
            </div>
        </form>
        <div v-if="loading" class="loader"></div> <!-- Afficher le loader -->
        <div v-if="error" class="error">{{ error }}</div>
    </div>
</template>

<script>
import axios from '../../services/axios_config';
export default {
    data() {
        return {
            user: {
                email: '',
                password: ''
            },
            error: null,
            loading: false // Variable pour suivre l'état de chargement
        };
    },
    methods: {
        async login() {
             this.loading = true; // Activer le loader
             this.error = null; // Réinitialiser les 
            try {
                const response = await axios.post('https://terrain.projetphoenix.net/api/Login', this.user);
                console.log('Connexion réussie:', response.data);

                // Vérifie si la réponse contient un jeton
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                    if (response.data.user && response.data.user.typeUtilisateur === 'comptable') {
                        this.$router.push('/transaction');// Rediriger vers la route appropriée
                    } else {
                        // Gérer le cas où l'utilisateur n'a pas le bon type d'utilisateur
                        alert('Vous n\'avez pas les permissions nécessaires pour accéder à cette page.');
                        // Vous pouvez rediriger vers une autre page ou faire d'autres actions nécessaires
                    }
                    // Redirection après une connexion réussie
                    // alert('Connexion réussie. Cliquez sur OK pour continuer.');
                    // this.$router.push('/transaction');
                } else {
                    throw new Error('Le jeton d\'authentification est manquant dans la réponse.');
                }
            } catch (error) {
                console.error('Erreur de connexion:', error);
                if (error.response) {
                    this.error = error.response.data.error || 'Une erreur s\'est produite lors de la connexion.';
                } else {
                    this.error = 'Une erreur s\'est produite lors de la connexion.';
                }
                alert('Vous n\'êtes pas inscrit ou votre information est incorrecte');
            }
            finally {
                this.loading = false; // Désactiver le loader dans tous les cas
            }
        }
    }
}
</script>


<style scoped>
@import './ConexCompt.css';

.cadre1 {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

input {
    font-size: medium;
    height: 35px;
    border: none;
    outline: none;
}

.input_conex {
    display: flex;
    margin-left: 20%;
    width: 10;
}

fieldset {
    width: 25%;
    margin-left: 53%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 1%;
    font-family: Arial, Helvetica, sans-serif;
    color: #429c2e;
    margin-bottom: 2%;
    padding-left: 0%;
}

.error {
    color: red;
    margin-left: 53%;
}
.loader {
    border: 16px solid #f3f3f3; /* Couleur de fond */
    border-radius: 50%; /* Forme ronde */
    border-top: 16px solid #3498db; /* Couleur de l'animation */
    width: 120px; /* Largeur du loader */
    height: 120px; /* Hauteur du loader */
    -webkit-animation: spin 2s linear infinite; /* Animation pour les navigateurs WebKit */
    animation: spin 2s linear infinite; /* Animation pour tous les navigateurs */
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
