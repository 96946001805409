<template>
    <div class="nav_bar_gauche">
        <div><img class="logo3" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
        <div>
            <nav>
                <ul>
                    <li> <a href="#"><img class="check" src="../../assets/Icon/file-check.svg" alt="">Information</a>
                    </li>
                    <li><a class="param" href="#"><img class="seting" src="../../assets/Icon/seting.svg" alt="">
                            Paramètre</a></li>
                    <li @click="logout"><a href="#"><img class="loug_out" src="../../assets/Icon/loug_out.svg" alt="">
                            Déconnexion</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            curent_token: localStorage.getItem('current_token')
        }
    },
    methods: {
        async logout() {
            try {
                const response = await axios.post('https://terrain.projetphoenix.net/api/logout'); // Assurez-vous que l'URL correspond à votre route backend
                delete localStorage.curent_token;
                alert(response.data.message); // Affichez le message de déconnexion
                this.$router.push('/'); // Redirigez l'utilisateur vers la page de connexion
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
                alert('Erreur lors de la déconnexion.');
            }
        }
    }

}

</script>

<style lang="css" scoped>
.nav_bar_gauche {
    background-color: #E7D2DF;
    width: 15%;
    /* Largeur relative */
    border-radius: 2%;
    float: left;
    box-sizing: border-box;
    margin: 1%;
    margin-top: -1%;
    position: fixed;
    /*Position fixe */
    left: 0;
    /* Position tout à gauche */
    /* flex-shrink: 0; */
}

body {
    padding-left: 15%;
    /* Ajoutez cette ligne */
    margin-left: 0;
    /* Réinitialisez cette valeur */
    box-sizing: border-box;
    overflow-x: hidden;
}

.logo3 {
    width: 50%;
    /* Largeur relative */
    margin: 5%;
    margin-left: 19%;
    /* Marge relative */
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    margin-bottom: 10px;
}

nav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
    width: 80%;
    /* Largeur relative */
    margin-left: 10%;
    /* Marge relative */
    padding: 2%;
    padding-left: 4%;
    border-radius: 10px;
    background-color: #E7D2DF;
    font-size: 1.2em;
    /* Taille de police relative */
}

nav ul li a:hover {
    background-color: white;
}

.check,
.seting,
.loug_out {
    width: 12%;
    /* Largeur relative */
    margin-right: 1vw;
    /* Marge relative */
}

.param {
    margin-top: 67vh;
    /* Position verticale relative */
}

/* Media queries pour les styles responsives */
@media screen and (max-width: 768px) {
    .nav_bar_gauche {
        width: 100%;
        /* Ajustement de la largeur pour les petits écrans */
        margin: 0;
        margin-bottom: 2%;
    }

    .logo3 {
        width: 70%;
        /* Ajustement de la largeur pour les petits écrans */
        margin: 3%;
        margin-left: 15%;
    }

    nav ul li a {
        width: 80%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-left: 5%;
        font-size: 1em;
        /* Ajustement de la taille de la police */
    }

    .check,
    .seting,
    .loug_out {
        width: 20%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-right: 2%;
    }

    .param {
        margin-top: 5vh;
        /* Ajustement de la position verticale pour les petits écrans */
    }
}
</style>
