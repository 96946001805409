<template>
    <div class="fond_affiche_liste">
        <Fond_G_Poste></Fond_G_Poste>
        <Nav_bar_haut></Nav_bar_haut>
        <P_affiche_listeEquipe_info></P_affiche_listeEquipe_info>
    </div>
</template>

<script>
import Fond_G_Poste from './Fond_G_Poste.vue'
import Nav_bar_haut from './Nav_bar_haut.vue'
import P_affiche_listeEquipe_info from './P_affiche_listeEquipe_info.vue'
export default {
    components: {
        Fond_G_Poste,
        Nav_bar_haut,
        P_affiche_listeEquipe_info,
    }
}
</script>

<style scoped>
.fond_affiche_liste,
.Calendrier_sans_selectionffiche_liste {
    background-color: #E7D2DF;
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    padding-left: 20%;
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}

/* Media query pour les styles responsives */
@media screen and (max-width: 768px) {
    .fond_affiche_liste {
        padding: 1%;
        /* Réduire le padding pour les petits écrans */
    }
}
</style>