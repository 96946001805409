<template>
    <div class="fond-arrière_admin_client">
        <Fond_gauche_admin></Fond_gauche_admin>
        <Nav_bar_haut></Nav_bar_haut>
        <liste_client></liste_client>
    </div>
</template>

<script>

import Fond_gauche_admin from './Fond_gauche_admin.vue'
import Nav_bar_haut from '../Poste_de_garde/Nav_bar_haut.vue';
import liste_client from './liste_client.vue'
export default {
    components: {
        Fond_gauche_admin,
        Nav_bar_haut,
        liste_client
    }
}
</script>

<style lang="css" scoped>
.fond-arrière_admin_client {
    background-color: #E7D2DF;
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    padding-left: 20%;
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}


@media screen and (max-width: 768px) {
    .fond-arrière_admin_client {
        padding: 1%;
    }
}
</style>