import { createRouter, createWebHistory } from 'vue-router';
import Page_Aceuill from './Acceuil/Page_Acueill.vue';
import Page_conexion from './Conexion_Client/Page_conexion.vue';
import Inscription_Client from './Inscription_Client/Inscription_Client.vue';
import Formulaire_Clien from './Formulaire_client/Formulaire_Clien.vue'
import Interface_transComptable from './InterfaceTransaction_Comptable/Interface_transComptable.vue';
import GestionPersonel from './GestionPersonelle/GestionPersonel.vue'
import Affiche_equipe from './InformationEquipe/Affiche_equipe.vue'
import Reservation_C from './Reservation_Client/Reservation_C.vue';
import Page_Poste_de_garde from './Poste_de_garde/Page_Poste_de_garde.vue';
import Page_Payement from './Payement_Client/Page_Payement.vue';
import Page_affiche_reser from './Affiche_reservation/Page_affiche_reser.vue';
import Page_pop_up from './Popup/Page_pop_up.vue';
import Poste_affiche_equipe from './Poste_de_garde/Poste_affiche_equipe.vue';
// import Inscription_Admin from './Inscription_Admin/Inscription_Admin.vue';
import Page_conexion_Poste_G from './Connexion_Poste_G/Page_conexion_Poste_G.vue';
import Inscription_Poste_de_garde from './Inscription_Poste_de_Garde/Inscription_Poste_de_garde.vue'
import Page_reservation_Admin from './Admin/Page_reservation_Admin.vue';
import Page_liste_personnelle from './Admin/Page_liste_personnelle.vue';
import Page_liste_client from './Admin/Page_liste_client.vue';
import Inscription_Admin from './inscription_Admin/Inscription_Admin.vue';
import Page_conexion_Administrateur from './Conexion_Administrateur/Page_conexion_Administrateur.vue';
import Page_conexion_Comptable from './Connexion_Comptable/Page_conexion_Comptable.vue';
import Inscription_comptable from './inscription_Comptable/Inscription_comptable.vue';
import liste_personnelle from './Admin/liste_personnelle.vue';
import Page_liste_Reservation from './Admin/Page_liste_Reservation';

const routes = [
  { path: '/', components: { Page1: Page_Aceuill } },
  { path: '/conexion', components: { Conex: Page_conexion } },
  { path: '/inscription', components: { inscri: Inscription_Client } },
  { path: '/formulairecli', components: { formcli: Formulaire_Clien } },
  { path: '/transaction', components: { Transc: Interface_transComptable } },
  { path: '/gestionpers', components: { Gest: GestionPersonel } },
  { path: '/informEquipe', components: { Infrme: Affiche_equipe } },
  { path: '/Popup', components: { Pop: Page_pop_up } },
  { path: '/Reservation', components: { Res: Reservation_C } },
  { path: '/Poste_G/:id_equipe', name: 'Post', components: { Post: Page_Poste_de_garde }, props: true },
  { path: '/Payement', components: { Pay: Page_Payement } },
  { path: '/AfficheReservation', components: { A_reservation: Page_affiche_reser } },
  { path: '/Poste_liste_equipe', components: { Liste_equipe: Poste_affiche_equipe } },
  { path: '/Inscri_Poste_G', components: { Inscription_poste: Inscription_Poste_de_garde } },
  { path: '/Connex_Poste_G', components: { Connexion_poste: Page_conexion_Poste_G } },
  { path: '/Connex_Administrateur', components: { Connexion_A: Page_conexion_Administrateur } },
  { path: '/Admin', components: { A_admin: Page_reservation_Admin } },
  { path: '/Admin_liste_personnelle', components: { A_ListePerso: Page_liste_personnelle } },
  { path: '/Admin_liste_client', components: { A_liste_client: Page_liste_client } },
  { path: '/Inscri_Admin', components: { Inscri_A: Inscription_Admin } },
  { path: '/Connex_Comptable', components: { Connexion_C: Page_conexion_Comptable } },
  { path: '/Inscri_Comptable', components: { Inscri_C: Inscription_comptable } },
  { path: '/liste_Personnelle', components: { Liste_P: liste_personnelle } },
  { path: '/Page_liste_Reservation', components: { Liste_G: Page_liste_Reservation} },




];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
