<template>
  <div class="hello">

    <div class="Nav bar" id="app">

      <div><img src="../../assets/image/background.jpg" alt=""></div>
      <div class="navbar">
        <ul class="liste">
          <li class="mahitsy"><a href="#home">Home</a></li>
          <li class="mahitsy"><a href="#contact">Contact</a></li>
          <li class="mahitsy"><img class="profil_accueille" src="../../assets/Icon/profil_nav_accueille.svg" alt=""
              @click="openModal"></li>
        </ul>
      </div>
      <div>
        <h1 class="ja">Jouer avec <span class="n">nous</span></h1>
      </div>
      <div><button class="btnreserver" @click="redirectToConexCompt">Réserver</button></div>




      <div><button class="btnguide">Guide d'utilisation</button></div>

      <div class="modal" v-if="isModalOpen">

        <div class="modal-content">
          <div class="redirect">
            <!-- Contenu de la pop-up -->
            <h3>Veuillez choisir le choix de connexion :</h3>
            <p class="sous_t_choix">Se connecter en tant que :</p>
            <div class="cadre_trois_profil">
              <div class="cadre_img" @click="RedirectToAdmin"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_admin.svg" alt="">
                <p>Admin</p>
              </div>
              <div class="cadre_img" @click="RedirectToComptable"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_compta.svg" alt="">
                <p>Comptable</p>
              </div>
              <div class="cadre_img" @click="RedirectToPoste_de_garde"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_poste.svg" alt="">
                <p>Poste de garde</p>
              </div>
            </div>
            <!-- Bouton pour fermer la pop-up -->
            <!-- <button class="pop_fermer" @click="closeModal">Términer</button> -->
          </div>
          <div class="close" @click="closeModal">x</div>
        </div>
      </div>
    </div>


  </div>


</template>

<script>
import routes from '../router'

export default {
  data() {
    return {
      isModalOpen: false // État de la modale, initialement fermée
    };
  },
  methods: {
    // Fonction pour ouvrir la pop-up
    openModal() {
      this.isModalOpen = true;
      document.body.classList.add('modal-open');
    },
    // Fonction pour fermer la pop-up
    closeModal() {
      this.isModalOpen = false;
      document.body.classList.remove('modal-open');
    },
    RedirectToComptable() {
      routes.push('/Connex_Comptable')
    },
    RedirectToPoste_de_garde() {
      routes.push('/Connex_Poste_G')
    },
    RedirectToAdmin() {
      routes.push('/Connex_Administrateur')
    },
    redirectToConexCompt() {
      // const routes = useRouter()
      routes.push('/conexion')
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './Accueil.css';

.modal-open {
  overflow: hidden;
}

.close {
  border-radius: 50%;
  background-color: #D9D9D9;
  width: 10%;
  height: 22%;
  font-size: xx-large;
  text-align: center;
  margin-top: -55%;
  margin-left: 103%;
  font-weight: lighter;
}

.sous_t_choix {
  margin-top: 3%;
}

.cadre_img p {
  margin-left: 3%;
  margin-top: 2%;
}

.cadre_trois_profil {
  margin-top: 5%;
  margin-left: 2%;
}

.cadre_img {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

.redirect {
  padding-left: 5%;
}

.ico_profil_emploier {
  width: 5%;
  height: auto;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fond semi-transparent pour l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #D9D9D9;
  padding: 10px;
  border-radius: 10px;
  /* display: flex; */
  align-items: center;
  width: 30%;
  height: 30%;
  cursor: pointer;
}


fieldset {
  padding: 5%;
  width: 60%;
}

.navbar {
  position: absolute;
  /* Position absolue pour superposer la barre de navigation */
  top: 0;
  /* Placer la barre de navigation en haut */
  left: 0;
  /* Placer la barre de navigation à gauche */
  width: 100%;
  /* Remplir toute la largeur */
  background-color: rgba(51, 51, 51, 0.65);
  /* Couleur de fond de la barre de navigation avec une opacité de 65% */
  overflow: hidden;
  z-index: 1;
  /* Pour s'assurer que la barre de navigation est au-dessus de l'image */
}

.navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #9e9393;
  color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Styles de la barre de navigation */

.profil_accueille {
  margin-top: 0.5vw;
  /* Utilisation de vw pour une taille relative à la largeur de la fenêtre */
  height: auto;
  border-radius: 50%;
  border: 1px solid white;
  padding: 1%;
  float: left;
  display: block;
  width: 10%;
  /* Largeur relative */
  margin-left: 7%;
  cursor: pointer;

  /* Marge relative */
}

@media screen and (max-width: 768px) {

  /* Exemple de breakpoint pour un écran de taille moyenne */
  .profil_accueille,
  .mahitsy,
  {
  width: 100%;
  /* Ajustement de la largeur pour les petits écrans */
  margin-left: 0;
  /* Suppression de la marge à gauche */
}

}


.droit {
  margin-bottom: 30%;
}


.liste {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 80%;
}

.mahitsy {
  display: inline;
}

img {
  width: 100%;
}

.ja {
  color: white;
  text-align: center;
  margin-top: -15%;
}

.n {
  color: #C80018;
  text-align: center;
}


.lts {
  /*list-style: ;
  /* Supprimer les puces de la liste */
  padding: 0;
  /* Supprimer les marges internes de la liste */
  margin-left: 15%;
}

.lts li {
  margin-bottom: 5px;
  /* Espacement entre les éléments de la liste */
}

.lts1 {
  list-style: none;
  /* Supprimer les puces de la liste */
  padding: 0;
  /* Supprimer les marges internes de la liste */
  margin-left: 10%;
}

.lts1 li {
  margin-bottom: 16%;
  /* Espacement entre les éléments de la liste */
}

button {
  margin-left: 45%;
  padding: 1%;
  width: 10%;
  background-color: #C80018;
  border-radius: 10px;
  border: none;
  margin-top: 2%;
  color: white;
  font-size: medium;
  margin-bottom: -0.5%;
}

.btnguide {
  width: 15%;
  margin-left: 42.5%;
}

.info1 {
  width: 50%;
  margin-top: 25%;
  margin-left: 20%;
}

.cadreA {
  width: 40%;
  float: left;
  box-sizing: border-box;
}

.cadreB {
  width: 30%;
  float: left;
  box-sizing: border-box;
  margin-top: 10%;
}

.cadreC {
  width: 30%;
  float: left;
  box-sizing: border-box;
  margin-top: 10%;
}


fieldset {
  padding: 5%;
  width: 60%;
}

.titrereser {
  padding-bottom: 5%;
}

.heure {
  position: relative;
  display: inline-block;
}

.stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.overlay {
  /* position: absolute; */
  /* Position absolue par rapport au conteneur */
  top: 10%;
  /* Distance depuis le haut */
  left: 0%;

  /* Distance depuis la gauche */
  border: none;
  width: 45%;
  /* Largeur du rectangle */
  height: 80%;
  /* Hauteur du rectangle */
  background-color: #C80018;
  /* Couleur de fond avec transparence */

  box-sizing: border-box;
  /* Inclure la bordure dans la taille totale */
  display: flex;
}

.titreheure {
  color: white;
  margin-top: 10%;
  margin-left: 22.5%;
  width: 100%;
}

.dtliste {
  color: white;
  font-weight: lighter;

  font-size: 85%;
  margin-top: 97%;
  list-style: none;
  margin-left: -380%;
}




.hrliste {
  font-weight: lighter;
  margin-top: 27.5%;
  list-style: none;
  margin-left: -82.5%;
  font-size: 170%;
}

.h_ouvre {
  list-style: none;
}

.liste_dt_h {
  font-size: 170%;
}

.liste_dt_h,
.hrliste {
  /* Pour les rendre en ligne et ajuster leur taille */
  display: inline-block;
  /* Pour ajouter de l'espace entre les divs si nécessaire */
}

.conditions {
  text-align: center;
}

.titre_C {
  margin-top: 5%;
  text-align: center;
  margin-bottom: 2%;
}

.liste_C {
  margin-left: 20%;
  list-style: none;
  margin-bottom: 3%;
}

.foot {
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}
</style>
