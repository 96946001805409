<template>
    <div class="cadre_calendrier_admin">
        <div class="calendar_admin">
            <div class="hour-column">
                <div class="hour">06h - 08h</div>
                <div class="hour">08h - 10h</div>
                <div class="hour">10h - 12h</div>
                <div class="hour">12h - 14h</div>
                <div class="hour">14h - 16h</div>
                <div class="hour">16h - 18h</div>
                <div class="hour">18h - 20h</div>
            </div>
            <div class="day_jour" v-for="(day, index) in days" :key="index">
                <div class="day-header">{{ day.name }}</div>
                <div class="day-date">{{ getCurrentDate(index) }}</div>
                <div class="day-body">
                    <div class="event" v-for="event in day.events" :key="event.name" :style="getEventStyle(event)">
                        <div class="pres_event">
                            <div class="event-name">{{ event.name }}</div>
                            <div>{{ event.time }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        startDate: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            days: [
                { name: "Lundi", events: [{ name: "MALAGA", color: "#E7D2DF" }, { name: "", color: "" }, { name: "", color: "" }, { name: "SODIAT", color: "#dcedc1" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Mardi", events: [{ name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "STAR", color: "#E7D2DF" }, { name: "BATIMAX", color: "#ff8b94" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Mercredi", events: [{ name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "OMNIS", color: "#dcedc1" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Jeudi", events: [{ name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "SIRAMA", color: "#ff8b94" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Vendredi", events: [{ name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "TAFA", color: "#dcedc1" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Samedi", events: [{ name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "SHOP-U", color: "#E7D2DF" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] },
                { name: "Dimanche", events: [{ name: "MIFA", color: "#dcedc1" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }, { name: "", color: "" }] }
            ],
            equipesMonth: [],
        };
    },
    methods: {
        getEventStyle(event) {
            return {
                backgroundColor: event.color,
                padding: '10px',
                margin: '3px 2px',
                borderRadius: '4px',
                color: '#fff',
                height: '94px',
            };
        },
        getCurrentDate(index) {
            const currentDate = new Date(this.startDate);
            currentDate.setDate(currentDate.getDate() + index);
            return currentDate.getDate();
        },
        adjustTextSize(event) {
            const element = event.target;
            while (element.scrollWidth > element.offsetWidth) {
                const fontSize = window.getComputedStyle(element).getPropertyValue('font-size');
                const newFontSize = parseInt(fontSize) - 1;
                element.style.fontSize = newFontSize + 'px';
            }
        },
        async fetchEquipesByCurrentMonth() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes/filter/current-monthA');
                this.equipesMonth = response.data;
                console.log('donnés des equipes filtrer par mois Le ray ', this.equipesMonth);
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes filtrées :', error);
            }
        },

    },
    mounted() {
        const eventNames = document.querySelectorAll('.event-name');
        eventNames.forEach(name => {
            this.adjustTextSize({ target: name });
        });
        this.fetchEquipesByCurrentMonth();
    }
};
</script>

<style>
body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    /* Définir une taille de police de base pour le corps */
}

/* Définir une taille de police réactive pour s'adapter à différentes tailles d'écran */
@media only screen and (max-width: 768px) {
    body {
        font-size: 14px;
    }
}

.cadre_calendrier_admin {
    text-align: center;
    padding: 20px;
}

.calendar_admin {
    display: flex;
    justify-content: center;
}

.day_jour {
    border: 1px solid #D9D9D9;
    border-right: none;
    border-top: none;
    border-bottom: none;
    width: 90px;
}

.day-header {
    font-weight: bold;
    margin-bottom: 10px;
}

.day-body {
    min-height: 70px;
}

.event {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bolder;
    /* font-size: 150%; */
    font-size: 1.5rem;
    /* Utilisation d'une taille de police réactive */
}

.hour {
    margin-top: 70%;
    margin-bottom: 35px;
    font-size: medium;
}

.hour-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    margin-top: 4%;
}



.event-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}
</style>