<template>
    <div class="form_pay">
        <div>
            <h1 class="h_titre">Votre payement</h1>
        </div>
        <div class="gauche_droite">
            <div class="gauche">
                <fieldset class="fieldset_g">
                    <legend>Nom d'abonnement</legend>
                    <input class="input_field" type="text" name="" id="" v-model="equipe.nomEquipe" readonly>
                </fieldset>
            </div>
            <div class="droite">
                <fieldset class="fieldset_d">
                    <legend>Numéro télephone</legend>
                    <input class="input_field" type="tel" name="" id="" placeholder="Entrer votre n° télephone">
                </fieldset>
            </div>
        </div>
        <div>
            <h3 class="h3_titre">Délais de réservation</h3>
        </div>
        <div class="gauche">
            <fieldset class="fieldset_g">
                <legend>Type de réservaton</legend>
                <input class="input_field" type="text" name="" id="" v-model="currentReservation.typeReservation"
                    readonly>



            </fieldset>
        </div>
        <div class="droite">
            <fieldset class="fieldset_d">
                <legend>Date et heure</legend>
                <input class="input_field" type="text" name="" id="" v-model="reservations.dateReservation" readonly>
            </fieldset>
        </div>

        <div>
            <fieldset class="fiedset_total">
                <legend>TOTAL DE PAYEMENT</legend>
                <input class="input_field" type="text" placeholder="veuiller entre le montant A payer" name="" id="">

            </fieldset>
        </div>
        <div class="btn_pre_ter">
            <div class="btn_pre_pay"><button class="btn_pre" @click="redirectToInformEquipe">Précédent</button></div>
            <div class="btn_ter_pay"><button class="btn_ter" @click="openModal">Términer</button></div>
        </div>
        <div>

            <!-- Modale -->
            <div class="modal" v-if="isModalOpen">

                <div class="modal-content" @click="closeModal">
                    <div class="redirect" @click="redirectToAfficheReservation">
                        <!-- Contenu de la pop-up -->
                        <img class="img_check" src="../../assets/Icon/ckeck_valid.svg" alt="">
                        <p>Votre payement est reussi avec succés.</p>
                        <!-- Bouton pour fermer la pop-up -->
                        <!-- <button class="pop_fermer" @click="closeModal">Términer</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import routes from '../router'
import axios from 'axios'
export default {
    data() {
        return {
            isModalOpen: false, // État de la modale, initialement fermée
            reservations: [], // Assurez-vous que c'est un tableau
            currentReservation: {}, // Utilisez un objet pour les données spécifiques
            equipe: {},
        };
    },
    mounted() {
        this.fetchEquipeData();
    },
    methods: {
        redirectToAfficheReservation() {
            routes.push('/AfficheReservation')
        },
        // Fonction pour ouvrir la pop-up
        openModal() {
            this.isModalOpen = true;
        },
        // Fonction pour fermer la pop-up
        closeModal() {
            this.isModalOpen = false;
        },
        redirectToInformEquipe() {
            routes.push('/formulairecli')
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes');
                const data = response.data;
                this.equipe = data.equipes[0];
                this.reservations = data.reservations[0];
                this.currentReservation = this.reservations; // Remplacez par la logique correcte si nécessaire
                console.log('Réservation ao Payment', this.reservations); // Vérifiez le format des données dans la console
                console.log('Sélection de réservation:', this.selectedReservation);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        getToken() {
            // Récupère le jeton depuis le stockage local
            return localStorage.getItem('token');
        },
    }

}
</script>

<style scoped>
.redirect {
    display: flex;
    align-items: center;
    /* Aligner les éléments verticalement au centre */
    justify-content: center;
    /* Aligner les éléments horizontalement au centre */
}

.img_check {
    width: 10%;
    margin-right: 5%;
    margin-left: 0%;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fond semi-transparent pour l'arrière-plan */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #C80018;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 30%;
    cursor: pointer;


}

.form_pay {
    position: absolute;
    left: 45%;
}

.h_titre {
    font-size: 3em;
    margin-top: 10%;
    /* Taille de police de base */
}

fieldset {
    width: 80%;
    padding: 5%;
    margin: 15% 0 10% 0;
    border-radius: 10px;
    border: 1px solid #808080;
}

.input_field {
    /* margin-left: 10%; */
    text-align: center;
    font-size: large;
    color: #808080;
}

input {
    border: none;

}

input:focus {
    outline: none;
}

.btn_pre_pay {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

.btn_ter_pay {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

.btn_pre {
    width: 70%;
    color: white;
    background-color: #41A62A;
    border-radius: 10px;
    padding: 5%;
    font-size: large;
    margin-left: 200%;
    margin-top: 13%;

}

.btn_ter {
    width: 70%;
    color: white;
    background-color: #41A62A;
    border-radius: 10px;
    padding: 5%;
    font-size: large;
    margin-left: 180%;
    margin-top: 13%;
}

.gauche {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

.droite {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;

}

.fieldset_d {
    margin-left: 100%;
    width: 150%;
    color: #41A62A;
}

.fieldset_g {
    width: 155%;
    color: #41A62A;

}

.fiedset_total {
    width: 77.5%;
    padding: 2.5%;
    margin-top: 40%;
    color: #41A62A;
}

.h3_titre {
    margin-top: 40%;
    color: #41A62A;
    font-weight: bolder;
}

@media screen and (max-width: 768px) {
    .h_titre {
        font-size: 2.5em;
        /* Ajustez la taille de police pour les écrans de largeur maximale de 768px */
    }
}
</style>