<template>
    <div>
        <!-- Bouton pour ouvrir la pop-up -->
        <button @click="openModal">Ouvrir la pop-up</button>

        <!-- Modale -->
        <div class="modal" v-if="isModalOpen" >
            <div class="modal-content">
                <!-- Contenu de la pop-up -->
                <h2>Pop-up</h2>
                <p>Ceci est le contenu de la pop-up.</p>
                <!-- Bouton pour fermer la pop-up -->
                <button @click="closeModal">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import routes from '../router'
export default {
    data() {
        return {
            isModalOpen: false // État de la modale, initialement fermée
        };
    },
    methods: {
        redirectToAfficheReservation() {
            routes.push('/AfficheReservation')
        },
        // Fonction pour ouvrir la pop-up
        openModal() {
            this.isModalOpen = true;
        },
        // Fonction pour fermer la pop-up
        closeModal() {
            this.isModalOpen = false;
        }
    }
};
</script>

<style scoped>
/* Style de la modale */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fond semi-transparent pour l'arrière-plan */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

/* Style du bouton */
button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
</style>