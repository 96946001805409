<template>
    <div class="foot">
        <Buton_Conex></Buton_Conex>
        <CadreGaucheConex></CadreGaucheConex>
        <Champ_Conex></Champ_Conex>
    </div>
</template>

<script>

import CadreGaucheConex from './Cadre-GaucheConex.vue'
import Champ_Conex from './Champ_Conex.vue';
import Buton_Conex from './Buton_Conex.vue';


export default {
    components: {
        CadreGaucheConex, Champ_Conex, Buton_Conex
    }

}
</script>


<style scoped>
.foot {
    background-color: white;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 100vh;
}
</style>
