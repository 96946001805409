<template>
    <div class="fond_arrière">
        <Fond_gauche_affiche_cli></Fond_gauche_affiche_cli>
        <Affiche_Calendar></Affiche_Calendar>
    </div>
</template>

<script>
import Fond_gauche_affiche_cli from '../InformationEquipe/Fond_gauche_affiche_cli.vue';
import Affiche_Calendar from './Affiche_Calendar.vue';
export default {
    components: {
        Fond_gauche_affiche_cli,
        Affiche_Calendar,
    }
}
</script>

<style scoped>
.fond_arrière {
    background-color: white;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 100vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    padding-left: 20%;
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}

/* Media query pour les styles responsives */
@media screen and (max-width: 768px) {
    .fond-arrière {
        padding: 1%;
        /* Réduire le padding pour les petits écrans */
    }
}
</style>