<template>
    <div id="app">
        <fond_gauche_inscri_Admin></fond_gauche_inscri_Admin>
        <champ_inscri_Admin></champ_inscri_Admin>

    </div>

</template>
<script>

import fond_gauche_inscri_Admin from './fond_gauche_inscri_Admin.vue'
import champ_inscri_Admin from './champ_inscri_Admin.vue'


export default {
    components: {
        fond_gauche_inscri_Admin,
        champ_inscri_Admin,

    },
}
</script>