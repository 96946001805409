<template>
    <div class="cadre_page_pay">
        <Fond_Gauche_Pay></Fond_Gauche_Pay>
        <Formulaire_payement></Formulaire_payement>
    </div>
</template>

<script>
import Fond_Gauche_Pay from './Fond_Gauche_Pay.vue'
import Formulaire_payement from './Formulaire_payement.vue'
export default {
    components: {
        Fond_Gauche_Pay,
        Formulaire_payement,
    }
}
</script>

<style scoped>
.cadre_page_pay {
    position: relative;
}
</style>