<template>
    <div class="cadre_admin_liste">
        <h3 class="t_listePerso">Listes des Réservations</h3>
        <div class="p_cadre_admin_liste">
            <table>
                <tr>
                    <td class="nom-utilisateur">Nom D'equipe</td>
                    <td class="e_mail">Date de création de Réservation</td>
                    <td class="e_mail">Date de Réservation</td>
                    <td class="poste">heure de Réservation</td>
                    <td class="vide"></td>
                </tr>
                <tr v-for="reservations in Reservation" :key="reservations.id">
                    <td class="centrer"><input type="text" :value=" reservations.equipe.nomEquipe" readonly></td>
                    <td class="centrer"><input type="text" :value=" reservations.created_at" readonly></td>
                    <td class="centrer"><input type="text" :value=" reservations.dateReservation" readonly></td>
                    <td class="centrer"><input type="text" :value=" reservations.heureReservation" readonly></td>
                    <td class="centrer"><input class="btn_supp" type="button" @click="deleteRéservation(reservations.id)" value="Supprimer"></td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default{
    data() {
        return {
            Reservation: []
        };
    },
       mounted() {
        this.fetchEquipeReservation();
    },
      methods: {
 
           async fetchEquipeReservation() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/EquipeReservation');
                const data = response.data;
               
                this.Reservation = data; // Assurez-vous que la réponse contient les données directement
                console.log(this.Reservation); // Vérifiez le format des données dans la console
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        async deleteRéservation(reservationsID) {
    // Afficher une boîte de dialogue de confirmation
    const confirmation = confirm('Voulez-vous vraiment supprimer cet Reservation ? Cette action est irréversible.');

    // Si l'utilisateur clique sur "Annuler", ne pas continuer
    if (!confirmation) {
        return;
    }

    try {
        await axios.delete(`https://terrain.projetphoenix.net/api/DeleteReserv/${reservationsID}`);
        this.fetchEquipeReservation(); // Recharge la liste après suppression
        alert('Réservation supprimé avec succès');
    } catch (error) {
        console.error('Erreur lors de la suppression de la Réservation :', error);
        alert('Erreur lors de la suppression de la Réservation');
    }
}

      }
}

</script>


<style scoped>
.cadre_admin_liste {
    display: inline-block;
    vertical-align: top;
    margin-top: 8%;
    top: 0;
    left: 0;
    width: 102.5%;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
}

.p_cadre_admin_liste {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    text-align: center;
    padding: 3% 3% 3% 3%;
}

table {
    width: 100%;
}



/* Définir la largeur pour chaque colonne */
.nom-utilisateur {
    width: 40%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.e_mail {
    width: 30%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.poste {
    width: 20%;
    border: none;
    text-align: center;
    /* Exemple de largeur */
}

.vide {
    width: 30%;
    border: none;
}


.centrer {
    text-align: center;
    border: none;
}

input {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    color: white;
    background-color: #D9D9D9;
}

.btn_supp {
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: #41A62A;
    color: white;
}

.t_listePerso {
    margin-bottom: 2%;
}
input {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    color: black; /* Change la couleur du texte en noir */
   background-color: #D9D9D9;
    font-weight: bold; /* Rend le texte en gras */
    text-align: center; /* Centrer le texte à l'intérieur de l'input */
}
</style>
