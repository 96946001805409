<template>
    <div class="nav_bar_gauche">
        <div><img class="logo3" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
        <div>
            <nav>
                <ul>
                      <li><router-link id="GestionReservation" to="/Page_liste_Reservation" active-class="active-link"><img class="check"
                                src="../../assets/Icon/calendar.svg" alt="">GestionReservation</router-link></li>
                    <li><router-link id="reservation" to="/Admin" active-class="active-link"><img class="check"
                                src="../../assets/Icon/calendar.svg" alt="">Réservation</router-link></li>
                
                    <li><router-link id="listes-personnelle" to="/Admin_liste_personnelle"
                            active-class="active-link"><img class="p_vert" src="../../assets/Icon/profil_vert.svg"
                                alt="">Listes
                            personnelle</router-link></li>
                    <li><router-link id="listes-clients" to="/Admin_liste_client" active-class="active-link"><img
                                class="p_vert" src="../../assets/Icon/profil_vert.svg" alt="">Listes
                            clients</router-link></li>
                            
                    <li><a id="parametre" class="param" href="#" active-class="active-link"><img class="seting"
                                src="../../assets/Icon/seting.svg" alt=""> Paramètre</a></li>
                                
                                 
                    
                            
                    <li @click="logout"><a id="deconnexion" href="#" active-class="active-link"><img class="loug_out"
                                src="../../assets/Icon/loug_out.svg" alt="">
                            Déconnexion</a></li>
                </ul>
            </nav>
        </div>
        <div class="modal" v-if="isModalOpen">

        <div class="modal-content">
          <div class="redirect">
            <!-- Contenu de la pop-up -->
            <h3>Veuillez choisir le choix de connexion :</h3>
            <p class="sous_t_choix">Se connecter en tant que :</p>
            <div class="cadre_trois_profil">
              <div class="cadre_img" @click="RedirectToAdmin"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_admin.svg" alt="">
                <p>Admin</p>
              </div>
              <div class="cadre_img" @click="RedirectToComptable"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_compta.svg" alt="">
                <p>Comptable</p>
              </div>
              <div class="cadre_img" @click="RedirectToPoste_de_garde"><img class="ico_profil_emploier"
                  src="../../assets/Icon/profil_poste.svg" alt="">
                <p>Poste de garde</p>
              </div>
            </div>
            <!-- Bouton pour fermer la pop-up -->
            <!-- <button class="pop_fermer" @click="closeModal">Términer</button> -->
          </div>
          <div class="close" @click="closeModal">x</div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import routes from '../router';
export default {
    data() {
        return {
            isModalOpen: false, // État de la modale, initialement fermée
        };
    },
    mounted() {
        const links = document.querySelectorAll('nav ul li a');

        links.forEach(link => {
            link.addEventListener('click', function (event) {
                // Prevent default behavior if necessary
                event.preventDefault();

                // Remove 'active' class from all links
                links.forEach(lnk => lnk.classList.remove('active'));

                // Add 'active' class to the clicked link
                this.classList.add('active');
            });
        });
    },
    methods: {
        // Fonction pour ouvrir la pop-up
        openModal() {
            this.isModalOpen = true;
            document.body.classList.add('modal-open');
        },
        // Fonction pour fermer la pop-up
        closeModal() {
            this.isModalOpen = false;
            document.body.classList.remove('modal-open');
        },
          RedirectToComptable() {
      routes.push('/Inscri_Comptable')
    },
    RedirectToPoste_de_garde() {
      routes.push('/Inscri_Poste_G')
    },
    RedirectToAdmin() {
      routes.push('/Inscri_Admin')
    },
    redirectToConexCompt() {
      // const routes = useRouter()
      routes.push('/conexion')
    },
        async logout() {
            try {
                const response = await axios.post('https://terrain.projetphoenix.net/api/logout'); // Assurez-vous que l'URL correspond à votre route backend
                delete localStorage.curent_token;
                alert(response.data.message); // Affichez le message de déconnexion
                this.$router.push('/'); // Redirigez l'utilisateur vers la page de connexion
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
                alert('Erreur lors de la déconnexion.');
            }
        }
    }

}
</script>

<style lang="css" scoped>
/* .active-link {
    color: black;
   
    background-color: white;
   
    text-decoration: none;
    
} */

.nav_bar_gauche {
    background-color: #E7D2DF;
    width: 15%;
    border-radius: 2%;
    float: left;
    box-sizing: border-box;
    margin: 1%;
    margin-top: -1%;
    position: fixed;
    left: 0;
}

body {
    padding-left: 15%;
    margin-left: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

.logo3 {
    width: 50%;
    margin: 5%;
    margin-left: 19%;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    margin-bottom: 10px;
}

nav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
    width: 80%;
    margin-left: 10%;
    padding: 2%;
    padding-left: 4%;
    border-radius: 10px;
    background-color: #E7D2DF;
    font-size: 1em;
}

nav ul li a:hover {
    background-color: white;
}

nav ul li a.active {
    background-color: white;
    color: #000;
}

.ajout {
    border-radius: 50px;
    background-color: #41A62A;
    padding: 2%;
    border: none;
}

.check,
.seting,
.loug_out,
.ajout,
.p_vert {
    width: 12%;
    margin-right: 1vw;
}

.param {
    margin-top: 40vh;
}

@media screen and (max-width: 768px) {
    .nav_bar_gauche {
        width: 100%;
        margin: 0;
        margin-bottom: 2%;
    }

    .logo3 {
        width: 70%;
        margin: 3%;
        margin-left: 15%;
    }

    nav ul li a {
        width: 80%;
        margin-left: 5%;
        font-size: 1em;
    }

    .check,
    .seting,
    .loug_out,
    .ajout,
    .p_vert {
        width: 20%;
        margin-right: 2%;
    }

    .param {
        margin-top: 5vh;
    }

    .modal-open {
  overflow: hidden;
}

.close {
  border-radius: 50%;
  background-color: #D9D9D9;
  width: 10%;
  height: 22%;
  font-size: xx-large;
  text-align: center;
  margin-top: -55%;
  margin-left: 103%;
  font-weight: lighter;
}

.sous_t_choix {
  margin-top: 3%;
}

.cadre_img p {
  margin-left: 3%;
  margin-top: 2%;
}

.cadre_trois_profil {
  margin-top: 5%;
  margin-left: 2%;
}

.cadre_img {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

.redirect {
  padding-left: 5%;
}

.ico_profil_emploier {
  width: 5%;
  height: auto;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fond semi-transparent pour l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #D9D9D9;
  padding: 10px;
  border-radius: 10px;
  /* display: flex; */
  align-items: center;
  width: 30%;
  height: 30%;
  cursor: pointer;
}
}
</style>
