<template>
    <div class="heure">
        <img src="../../assets/image/Rectangle 24.jpg" alt="">
        <div class="stroke"></div>
        <div class="overlay">
            <div class="titreheure">
                <h1>Heure ouvrable par jour</h1>
            </div>
            <div class="liste_dt_h">
                <ul class="dtliste">
                    <li>
                        <p>Lundi à Vendredi</p>
                    </li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>Samedi</li>
                    <li>Dimanche</li>
                </ul>
            </div>
            <div class="hrliste">
                <ul class="h_ouvre">
                    <li>12H - 14H</li>
                    <li>16H - 18H</li>
                    <li>18H - 20H</li>
                    <li>8H - 20H</li>
                    <li>7H - 20H</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Styles de la barre de navigation */


.droit {
    margin-bottom: 30%;
}


.liste {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: 80%;
}

.mahitsy {
    display: inline;
}

img {
    width: 100%;
}

.ja {
    color: white;
    text-align: center;
    margin-top: -15%;
}

.n {
    color: #C80018;
    text-align: center;
}


.lts {
    /*list-style: ;
    /* Supprimer les puces de la liste */
    padding: 0;
    /* Supprimer les marges internes de la liste */
    margin-left: 15%;
}

.lts li {
    margin-bottom: 5px;
    /* Espacement entre les éléments de la liste */
}

.lts1 {
    list-style: none;
    /* Supprimer les puces de la liste */
    padding: 0;
    /* Supprimer les marges internes de la liste */
    margin-left: 10%;
}

.lts1 li {
    margin-bottom: 16%;
    /* Espacement entre les éléments de la liste */
}

button {
    margin-left: 45%;
    padding: 1%;
    width: 10%;
    background-color: #C80018;
    border-radius: 10px;
    border: none;
    margin-top: 2%;
    color: white;
    font-size: medium;
    margin-bottom: -0.5%;
}

.btnguide {
    width: 15%;
    margin-left: 42.5%;
}

.info1 {
    width: 50%;
    margin-top: 25%;
    margin-left: 20%;
}

.cadreA {
    width: 40%;
    float: left;
    box-sizing: border-box;
}

.cadreB {
    width: 30%;
    float: left;
    box-sizing: border-box;
    margin-top: 10%;
}

.cadreC {
    width: 30%;
    float: left;
    box-sizing: border-box;
    margin-top: 10%;
}


fieldset {
    padding: 5%;
    width: 60%;
}

.titrereser {
    padding-bottom: 5%;
}

.heure {
    position: relative;
    display: inline-block;
}

.stroke {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.overlay {
    position: absolute;
    /* Position absolue par rapport au conteneur */
    top: 10%;
    /* Distance depuis le haut */
    left: 0%;

    /* Distance depuis la gauche */
    border: none;
    width: 45%;
    /* Largeur du rectangle */
    height: 80%;
    /* Hauteur du rectangle */
    background-color: #C80018;
    /* Couleur de fond avec transparence */

    box-sizing: border-box;
    /* Inclure la bordure dans la taille totale */
    display: flex;
}

.titreheure {
    color: white;
    margin-top: 10%;
    margin-left: 22.5%;
    width: 100%;
}

.dtliste {
    color: white;
    font-weight: lighter;

    font-size: 85%;
    margin-top: 97%;
    list-style: none;
    margin-left: -380%;
}




.hrliste {
    font-weight: lighter;
    margin-top: 27.5%;
    list-style: none;
    margin-left: -82.5%;
    font-size: 170%;
}

.h_ouvre {
    list-style: none;
}

.liste_dt_h {
    font-size: 170%;
}

.liste_dt_h,
.hrliste {
    /* Pour les rendre en ligne et ajuster leur taille */
    display: inline-block;
    /* Pour ajouter de l'espace entre les divs si nécessaire */
}

.conditions {
    text-align: center;
}

.titre_C {
    margin-top: 5%;
    text-align: center;
    margin-bottom: 2%;
}

.liste_C {
    margin-left: 20%;
    list-style: none;
    margin-bottom: 3%;
}

.foot {
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;
    font-size: 14px;
}
</style>