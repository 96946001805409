<template>    
    <div id="app">
            <!--  -->
    </div>
</template>
<script>
  
</script>
<style scoped>
 @import './ConexCompt.css';
 .cadre1 {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}
</style>