<template>
    <div class="cadre_admin">
        <h3>Réservation</h3>
        <div class="cadre_affiche_date_courant_listeEquipe">
            <h2 class="mois_année">{{ currentMonthName }} {{ year }}</h2>
            <div class="cadre_affiche_semaine_courant_listeEquipe">
                <button class="btn_semaine_l" @click="decrementWeek">&lt;</button>
                <div class="t_semaine">
                    <p>Semaine</p>
                    <p class="nbr_semaine">{{ currentWeek }}</p>
                </div>
                <button class="btn_semaine_r" @click="incrementWeek">&gt;</button>
            </div>
        </div>
        <div class="g_cadre_calendar_admin">
            <Calendrier_admin :startDate="startDate"></Calendrier_admin>
        </div>
        <div class="cadre_afficheListe_gauche">
            <div class="calendrier_afficheListeEquipe">
                <Calendrier_sans_selection></Calendrier_sans_selection>
            </div>
            <div class="liste_equipe_jourCourant">
                <h3>Réservation aujourd'hui</h3>
                <div>
                    <p class="d__t_courant">{{ formattedCurrentDate }}</p>
                </div>
                <div class="cadre_listeEquipe" v-for="equipe in equipesToday" :key="equipe.id">
                    <img class="book_equipe" src="../../assets/Icon/book.svg" alt="">
                    <div>
                        <p class="n_equipe">{{ equipe.nomEquipe }}</p>
                    </div>
                    <p class="h-selection">heure sélectionné
                        <span class="heure-reservation" v-for="(heure, idx) in equipe.heureReservation.split(', ')"
                            :key="idx">
                            {{ heure }}
                            <span v-if="idx !== equipe.heureReservation.split(', ').length - 1">, </span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Calendrier_admin from './Calendrier_admin.vue';
import Calendrier_sans_selection from '../Poste_de_garde/Calendrier_sans_selection.vue';
import axios from 'axios';

export default {
    components: {
        Calendrier_admin,
        Calendrier_sans_selection,
    },
    data() {
        const date = new Date();
        const monthIndex = date.getMonth();
        const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        return {
            currentDate: date,
            year: date.getFullYear(),
            currentMonthIndex: monthIndex,
            currentMonthName: months[monthIndex],
            months: months,
            currentWeek: this.getCurrentWeekNumber(),
            reservations: [],
            equipes: [],
            equipesMonth: [],
            equipesToday: [],
        };
    },
    computed: {
        startDate() {
            const firstDayOfMonth = new Date(this.year, this.currentMonthIndex, 1);
            const dayOfWeek = firstDayOfMonth.getDay() || 7; // Get the day of the week, treating Sunday (0) as 7
            const weekOffset = (this.currentWeek - 1) * 7;
            return new Date(this.year, this.currentMonthIndex, 1 + weekOffset - (dayOfWeek - 1));
        },
        totalWeeksInMonth() {
            const lastDayOfMonth = new Date(this.year, this.currentMonthIndex + 1, 0);
            return Math.ceil((lastDayOfMonth.getDate() + (new Date(this.year, this.currentMonthIndex, 1).getDay() || 7) - 1) / 7);
        },
        formattedCurrentDate() {
            return this.formatDate(this.currentDate);
        }
    },
    mounted() {

        this.fetchEquipesByCurrentMonth();
        this.fetchEquipesToday();
    },
    methods: {
        getCurrentWeekNumber() {
            const date = new Date();
            const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const dayOfMonth = date.getDate();
            const dayOfWeek = startOfMonth.getDay() || 7; // Treat Sunday as 7
            return Math.ceil((dayOfMonth + dayOfWeek - 1) / 7);
        },
        decrementWeek() {
            if (this.currentWeek > 1) {
                this.currentWeek--;
            } else {
                this.decrementMonth();
                this.currentWeek = this.totalWeeksInMonth;
            }
        },
        incrementWeek() {
            if (this.currentWeek < this.totalWeeksInMonth) {
                this.currentWeek++;
            } else {
                this.incrementMonth();
            }
        },
        decrementMonth() {
            if (this.currentMonthIndex > 0) {
                this.currentMonthIndex--;
            } else {
                this.currentMonthIndex = 11;
                this.year--;
            }
            this.updateMonth();
        },
        incrementMonth() {
            if (this.currentMonthIndex < 11) {
                this.currentMonthIndex++;
            } else {
                this.currentMonthIndex = 0;
                this.year++;
            }
            this.updateMonth();
        },
        updateMonth() {
            this.currentMonthName = this.months[this.currentMonthIndex];
            this.currentWeek = 1;
        },
        formatDate(date) {
            const day = date.getDate();
            const month = this.months[date.getMonth()];
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        async fetchEquipesToday() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes/filter/current-date');
                this.equipesToday = response.data;
                console.log('Données des équipes aujourd\'hui :', this.equipesToday); // Vérifiez ici les données reçues
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes pour aujourd\'hui :', error);
            }
        },
        async fetchEquipesByCurrentMonth() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes/filter/current-monthA');
                this.equipesMonth = response.data;
                console.log('donnés des equipes filtrer par mois ', this.equipesMonth);
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes filtrées :', error);
            }
        },



    }
};
</script>


<style lang="css" scoped>
.cadre_afficheListe_gauche {
    width: 90%;
    margin-left: 10%;
}

.g_cadre_calendar_admin {
    width: 75%;
}

.t_semaine {
    margin: 0%;
    margin-top: 11%;
    margin-left: 20%;
    margin-right: 20%;
    background-color: #CEE8C8;
    padding: 5%;
    padding-right: 10%;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.nbr_semaine {
    margin-left: 5%;
}

.btn_semaine_r {
    width: 100%;
    background-color: #CEE8C8;
    border-radius: 5px;
    padding: 5%;
    margin-left: 0%;
    display: flex;
    align-items: center;
    color: black;
    margin-top: 10%;
}

.btn_semaine_l {
    width: 100%;
    background-color: #CEE8C8;
    border-radius: 5px;
    padding: 5%;
    color: black;
    margin-top: 10%;
}

.cadre_affiche_semaine_courant_listeEquipe {
    display: flex;
    align-items: center;
}

.cadre_affiche_date_courant_listeEquipe {
    display: flex;
    align-items: center;
}

.mois_année {
    padding-top: 1%;
    font-weight: lighter;
    font-size: large;
}

.cadre_admin {
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    width: 100%;
    /* Ajustez selon vos besoins */
}

.calendrier_afficheListeEquipe {
    margin-top: -82%;

}

.liste_equipe_jourCourant {
    margin-left: 70%;
}

.d__t_courant {
    margin-top: 3%;
    color: #808080;
}

.cadre_listeEquipe {
    border: 1px solid #D9D9D9;
    padding: 2%;
    padding-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
    max-height: 457px;
    /* Ajustez cette valeur en fonction de la hauteur souhaitée */
    overflow-y: auto;
}

.cadre_listeEquipe::-webkit-scrollbar {
    width: 8px;
}

.cadre_listeEquipe::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.cadre_listeEquipe::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}


.n_equipe {
    color: #41A62A;
}

.book_equipe {
    width: 8%;
    margin-left: 5%;
    margin-bottom: 5%
}

.h-selection {
    color: #808080;
}
</style>