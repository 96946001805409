<template>
    <div id="app">
        <fond_gauche_inscri></fond_gauche_inscri>
        <champ_inscri></champ_inscri> 

    </div>

</template>
<script>

import fond_gauche_inscri from './fond_gauche_inscri.vue'
import champ_inscri from './champ_inscri.vue'


export default {
    components : {
    fond_gauche_inscri,
    champ_inscri,
    
},
}
</script>