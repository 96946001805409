import { createApp } from 'vue';
import App from './App.vue';
import router from './components/router';
import 'tailwindcss/tailwind.css';
import axios from 'axios';

// Vue.config.productionTip = false;

// Configurer Axios (optionnel)
axios.defaults.baseURL = 'http://localhost:8000/api';

 // Importez le fichier de configuration du router

const app = createApp(App);
app.use(router); // Utilisez Vue Router ici
app.mount('#app');
