<template>
    <div class="container">
        <Fond_Gauche_Reser></Fond_Gauche_Reser>
        <DateCalendar></DateCalendar>
    </div>
</template>

<script>

    import Fond_Gauche_Reser from "./Fond_Gauche_Reser.vue";
    import DateCalendar from "./DateCalendar.vue"; 
    export default{
        components:{
            Fond_Gauche_Reser,
            DateCalendar,
        }
    }
</script>

<style lang="css" scoped>
.container {
  display: flex;
  align-items: flex-start; /* Aligner les composants en haut */
}
</style>