<template>
    <div class="cadre_afficheListe_equipe_poste">
        <div class="division1">
            <div class="cadre_liste_fotsiny">
                <h3>Informations</h3>
                <div>
                    <h2 class="mois_année">{{ currentMonthName }} {{ year }}</h2>
                </div>
                <div>
                    <fieldset class="fieldset_affiche_listeEquipe">
                        <legend class="titre_listeEquipe_affiche">Listes des équipes</legend>
                        <div>
                            <div v-for="equipe in equipes" :key="equipe.id">
                                <p class="nom_equipe_semaine" @click="selectEquipe(equipe.id)">{{ equipe.nomEquipe }}
                                </p>
                            </div>
                            <p v-if="equipes.length === 0" class="nom_equipe_semaine">Aucune équipe trouvée ce mois-ci.
                            </p>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="cadre_afficheListe_gauche">
                <div class="calendrier_afficheListeEquipe">
                    <Calendrier_sans_selection></Calendrier_sans_selection>
                </div>
                <div class="liste_equipe_jourCourant">
                    <h3>Réservation aujourd'hui</h3>
                    <p class="d__t_courant">{{ currentDate }}</p>
                    <div v-if="equipesToday.length === 0">
                        <p>Aucune équipe n'a de réservation pour aujourd'hui.</p>
                        <p>(Tsy misy ekipa nanao famandrihana toerana androany)</p>
                    </div>
                    <div v-else>
                        <div class="cadre_listeEquipe" v-for="equipe in equipesToday" :key="equipe.id"
                            @click="selectEquipe(equipe.id)">
                            <img class="book_equipe" src="../../assets/Icon/book.svg" alt="Icone livre">
                            <div>
                                <p class="n_equipe">{{ equipe.nomEquipe }}</p>
                            </div>
                            <p class="h-selection">
                                Heure(s) sélectionnée(s):
                                <span class="heure-reservation"
                                    v-for="(heure, idx) in equipe.heureReservation.split(', ')" :key="idx">
                                    {{ heure }}
                                    <span v-if="idx !== equipe.heureReservation.split(', ').length - 1">, </span>
                                </span>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import routes from '../router'
import Calendrier_sans_selection from './Calendrier_sans_selection.vue';
import axios from 'axios';


export default {
    props: {
        id_equipe: {
            type: String,  // Utilisez String pour les bigint dans les props
            required: true
        }
    },
    data() {
        const date = new Date();
        const monthIndex = date.getMonth();
        const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        return {
            currentDate: this.getCurrentDate(),
            year: date.getFullYear(),
            currentMonthIndex: monthIndex,
            currentMonthName: months[monthIndex],
            reservations: [],
            equipes: [],
            equipesToday: [],
        };
    },
    mounted() {
        this.fetchEquipeData();
        this.fetchEquipesByCurrentMonth();
        this.fetchEquipesToday();
    },
    components: {
        Calendrier_sans_selection,
    },
    methods: {
        selectEquipe(id_equipe) {
            console.log('ID d\'équipe sélectionnée :', id_equipe);
            if (id_equipe) {
                this.$router.push({ name: 'Post', params: { id_equipe: id_equipe } });
            } else {
                console.error("ID d'équipe non défini lors de la navigation.");
            }
        },

        formatHeure(heure) {
            // Formatage de l'heure ici, par exemple '14h - 16h'
            return `${heure.heureDebut}h - ${heure.heureFin}h`;
        },
        getCurrentDate() {
            const date = new Date();
            return this.formatDate(date);
        },
        formatDate(date) {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        async fetchEquipesToday() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes/filter/current-date');
                this.equipesToday = response.data;
                console.log('Données des équipes aujourd\'hui :', this.equipesToday); // Vérifiez ici les données reçues
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes pour aujourd\'hui :', error);
            }
        },


        async fetchEquipesByCurrentMonth() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipes/filter/current-month');
                this.equipes = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des équipes filtrées :', error);
            }
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get('https://terrain.projetphoenix.net/api/equipesB');
                const data = response.data;
                this.equipes = data.equipes;
                this.reservations = data.reservations;

                console.log("Données brutes de réservation:", this.reservations);
                console.log("Equipes filtrées pour le mois actuel:", this.filterEquipesByCurrentMonth());

            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        parseCustomDate(dateString) {
            const parts = dateString.split('/');
            return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
        },
        filterEquipesByCurrentMonth() {
            const currentMonth = this.currentMonthIndex;
            const currentYear = this.year;
            let equipeNames = new Set();

            this.reservations.forEach(reservation => {
                const reservationDate = this.parseCustomDate(reservation.dateReservation);
                console.log("Traitement de la réservation:", reservation);
                if (
                    reservationDate.getMonth() === currentMonth &&
                    reservationDate.getFullYear() === currentYear
                ) {
                    const equipe = this.equipes.find(equipe => equipe.id === reservation.equipe_id);
                    if (equipe) {
                        equipeNames.add(equipe.nomEquipe);
                        console.log("Nom d'équipe associé à la réservation:", equipe.nomEquipe);
                    } else {
                        console.log("Aucune équipe trouvée pour la réservation avec l'ID d'équipe:", reservation.id_equipe);
                    }
                }
            });

            return Array.from(equipeNames);
        }
        ,
    }
}
</script>






<style scoped>
.heure-reservation {
    display: block;
    /* Forcing each span to behave like a block element */
    margin-top: 5px;
    /* Optional: add some spacing between the lines */
}

.cadre_afficheListe_gauche {

    margin-left: -100%;
}

.division1 {
    display: flex;
}

.cadre_afficheListe_gauche,
.cadre_liste_fotsiny {
    flex: 1;
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
}

.cadre_afficheListe_gauche,
.cadre_liste_fotsiny {
    margin-right: 10px;
    /* Marge à droite de la première div */
}

.cadre_liste_fotsiny {
    margin-top: 0%;
    /* width: 70%; */
}

.h-selection {
    color: #808080;
}

.titre_listeEquipe_affiche {
    color: #41A62A;
    margin-left: 5%;
}

.nom_equipe_semaine {
    border: 1px solid #D9D9D9;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #808080;
    padding-left: 10%;
    padding-bottom: 5%;
    padding-top: 5%;
}

table {
    border-collapse: collapse;
    margin-top: -5%;
}

.d__t_courant {
    margin-top: 3%;
    color: #808080;
}

.n_equipe {
    color: #41A62A;
}

.book_equipe {
    width: 8%;
    margin-left: 5%;
    margin-bottom: 5%
}

.cadre_listeEquipe {
    border: 1px solid #D9D9D9;
    padding: 2%;
    padding-left: 5%;
    margin-top: 10%;
    margin-bottom: 5%;
    border-radius: 10px;
}

.liste_equipe_jourCourant {
    margin-left: 70%;
}

.calendrier_afficheListeEquipe {
    margin-top: 5%;
}

.fieldset_affiche_listeEquipe {
    margin-left: 0%;
    margin-top: 1%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    width: 65%;
    padding: 3% 0% 0% 0%;
}

.mois_année {
    padding-top: 1%;
    font-weight: lighter;
    font-size: large;
}

.cadre_afficheListe_equipe_poste {
    margin-top: 10%;
    /* display: flex; */
    /* Uncomment to allow height adjustment */
    vertical-align: top;
    background-color: white;
    border-radius: 10px;
    /* width: 100%; */
    padding: 2%;
    box-sizing: border-box;
}

/* Optional clearfix in case of floated children */
.cadre_afficheListe_equipe_poste::after {
    content: "";
    display: table;
    clear: both;
}
</style>
