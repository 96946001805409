<template>

    <div id="app">
        <div class="cadreB_g">
            <div><img class="logo1" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
            <div>
                <h1 class="conex">SE CONNECTER</h1>
            </div>
            <div>
                <p class="text">Vous devez être membres de la Commission Sport pour vous connecter</p>
            </div>
            <!-- <div><a class="inscri" href="">Pas de Compte ? S'incrire</a></div> -->
            <!-- <router-link class="inscri" to="/Inscri_Poste_G">Pas de Compte ? S'incrire</router-link> -->
        </div>
    </div>

</template>
<script>

export default {
    name: 'CadreGauche_Compt',
};

</script>

<style scoped>
@import'./ConexCompt.css';

.cadreB_g {
    width: 50%;
    /* border: 2px solid; */
    padding-bottom: 6.5%;
    background-color: #5167a3;
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin-top: -2%;
    margin-left: -2%;
    /* padding-bottom: 1%; */

}
</style>