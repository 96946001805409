<template>
    <div class="cadre_affiche_p_d_g">

        <div class="rectangle">

            <div class="cadre_affiche_info">
                <div class="cote">
                    <div class="cote_g"></div>
                    <h3>Information (Mombamomba)</h3>
                    <div class="date-selector">
                        <div>
                            <p class="titre_C">Match <span class="du">du</span> :</p>
                        </div>
                        <div class="affiche_date">
                            <div class="date-display">{{ currentDate }}</div>
                        </div>
                    </div>
                    <div class="cadre_btn">
                        <button class="btn_h_p">6h - 8h</button>
                        <button class="btn_h_p">8h - 10h</button>
                        <button class="btn_h_p">10h - 12h</button>
                        <button class="btn_h_p">12h - 14h</button>
                        <button class="btn_h_p">14h - 16h</button>
                        <button class="btn_h_p">16h - 18h</button>
                        <button class="btn_h_p">18h - 20h</button>
                    </div>
                    <!-- <h1>Page Poste de Garde - ID Équipe: {{ id_equipe }}</h1> -->
                    <div class="cadre_fieldset">
                        <fieldset class="field-poste-equipe">
                            <legend>Equipe</legend>
                            <p class="nom_info">{{ equipe.nomEquipe }}</p>
                        </fieldset>
                        <fieldset class="field-poste-responsable">
                            <legend>Responsable</legend>
                            <p class="nom_info">{{ responsable.nomResponsable }}</p>
                        </fieldset>
                    </div>
                </div>
                <div class="p_affiche_liste">
                    <fieldset class="p_fieldser_liste">
                        <legend class="legend_liste">Liste joueurs / CIN</legend>
                        <table class="tableau_liste">
                            <tr>
                                <td width="60%" class="enlever_border_top">Nom des joueurs (Anaran'ny ekipa)</td>
                                <td width="30%" class="enlever_border_top">CIN (Lah Karapanondro)</td>
                                <td width="10%" class="enlever_border_top_d">Présences (Fanamariham-pahatongavana)</td>
                            </tr>
                            <tr v-for="joueur in joueurs" :key="joueur.cinJoueur">
                                <td class="enlever_border_2_l">{{ joueur.nomJoueur }}</td>
                                <td class="enlever_border_2_b">{{ joueur.cinJoueur }}</td>
                                <td class="enlever_border_2_r">
                                    <table>
                                        <tr class="space">
                                            <div class="centrer">
                                                <td class="present">
                                                    <label for="">P</label>
                                                    <input type="checkbox" class="inpt_checkbox_p">
                                                </td>
                                            </div>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                </div>
                <div class="rapport">
                    <h4>Rapport (Tatitra)</h4>
                    <div>
                        <input class="inpt_rapport" type="text"
                            placeholder="Entrer votre rapport (Soraty eto ireo tatitra)">
                        <button class="btn_envoyer">Envoyer</button>
                    </div>
                </div>
                <!-- <div class="cadre_afficheListe_gauche_info">
                    <div class="c_calendrier">
                        <Calendrier_sans_selection></Calendrier_sans_selection>
                    </div>
                </div> -->
            </div>

        </div>

    </div>
</template>

<script>
import axios from 'axios';

// import Calendrier_sans_selection from './Calendrier_sans_selection.vue';

export default {
    props: {
        id_equipe: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentDate: this.getCurrentDate(),
            responsable: {
                idResponsable: '',
                nomResponsable: '',
                telResponsable: '',
                emailResponsable: '',
            },
            equipe: {
                nomEquipe: '',
            },
            joueurs: [],
        };
    },
    // components: {
    //     Calendrier_sans_selection,
    // },
    created() {
        console.log("id_equipe dans PosteInfo : ", this.id_equipe);
    },
    mounted() {
        this.fetchEquipeData();
    },
    methods: {
        getCurrentDate() {
            const date = new Date();
            return this.formatDate(date);
        },
        formatDate(date) {
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            return `${day} ${month} ${year}`;
        },
        previousDate() {
            const dateParts = this.currentDate.split(' ');
            const day = parseInt(dateParts[0], 10);
            const month = dateParts[1];
            const year = parseInt(dateParts[2], 10);

            let currentDate = new Date(year, this.getMonthNumber(month), day);
            currentDate.setDate(currentDate.getDate() - 1);

            this.currentDate = this.formatDate(currentDate);
        },
        nextDate() {
            const dateParts = this.currentDate.split(' ');
            const day = parseInt(dateParts[0], 10);
            const month = dateParts[1];
            const year = parseInt(dateParts[2], 10);

            let currentDate = new Date(year, this.getMonthNumber(month), day);
            currentDate.setDate(currentDate.getDate() + 1);

            this.currentDate = this.formatDate(currentDate);
        },
        getMonthNumber(monthName) {
            const months = {
                'janvier': 0, 'février': 1, 'mars': 2, 'avril': 3, 'mai': 4, 'juin': 5,
                'juillet': 6, 'août': 7, 'septembre': 8, 'octobre': 9, 'novembre': 10, 'décembre': 11
            };
            return months[monthName.toLowerCase()];
        },
        async fetchEquipeData() {
            try {
                const response = await axios.get(`https://terrain.projetphoenix.net/api/equipes/${this.id_equipe}`);
                const data = response.data;
                this.equipe = {
                    nomEquipe: data.nomEquipe,
                };
                this.responsable = {
                    nomResponsable: data.nomResponsable,
                };
                this.joueurs = data.joueurs;
                console.log(this.equipe, this.responsable, this.joueurs); // Vérifiez les données dans la console pour assurer qu'elles sont correctes
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },

    }
};
</script>

<style lang="css" scoped>
.cadre_afficheListe_gauche {

    margin-left: -100%;

}

.c_calendrier {
    margin-top: -90%;
}

.btn_envoyer {
    color: white;
    background-color: #41A62A;
    border-radius: 10px;
    width: 20%;
    margin: 5%;
    margin-left: 148.5%;
    margin-bottom: %;
}

.inpt_rapport {
    margin-top: 2%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    width: 169%;
    padding-left: 4%;
}

.rapport {
    margin-top: 3%;
}

.enlever_border_2_r {
    border-right: none;
    border-bottom: none;
    border-color: #D9D9D9;
    padding-top: 3%;
    text-align: center;
    color: black;
}

.enlever_border_2_b {
    border-bottom: none;
    border-color: #D9D9D9;
    padding-top: 3%;
    text-align: center;
    color: black;
}

.enlever_border_2_l {
    border-left: none;
    border-bottom: none;
    border-color: #D9D9D9;
    padding-top: 3%;
    text-align: center;
    color: black;
}

.enlever_border_top_d {
    border-right: none;
    border-color: #D9D9D9;
    padding-top: 3%;
    border-top: none;
    text-align: center;
    color: black;

}



.enlever_border_top {
    border-top: none;
    border-left: none;

    border-color: #D9D9D9;
    padding-top: 3%;
    text-align: center;
    color: black;
}

.legend_liste {
    margin-left: 5%;
}

.b_none {
    border-top: none;

}

.centrer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.present {
    border: none;
    text-align: center;

}

.abscent {
    border: none;
}

/* .inpt_checkbox_a {
    width: 100%;
} */

/* Style de la case à cocher */
.inpt_checkbox_a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    /* Largeur de la case à cocher */
    height: 20px;
    /* Hauteur de la case à cocher */
    border: 2px solid #ccc;
    /* Bordure de la case à cocher */
    border-radius: 3px;
    /* Rayon de la bordure */
    cursor: pointer;
    /* Curseur */
    outline: none;
    /* Supprime la bordure lorsqu'elle est cliquée */
}

/* Style de la case à cocher cochée */
.inpt_checkbox_a:checked::before {
    content: '\2713';
    /* Symbole de coche Unicode */
    display: block;
    width: 16px;
    /* Largeur de la coche */
    height: 16px;
    /* Hauteur de la coche */
    background-color: #41A62A;
    /* Couleur de fond de la coche lorsqu'elle est cochée */
    text-align: center;
    line-height: 16px;
    /* Hauteur de ligne pour centrer le symbole */
    color: #fff;
    /* Couleur du texte */
    font-size: 14px;
    /* Taille de la police */
}

/* Style de la case à cocher lorsqu'elle est survolée */
.inpt_checkbox_a:hover {
    border-color: #D9D9D9;
    /* Couleur de la bordure lors du survol */
}

/* .inpt_checkbox_p {
    width: 100%;
} */

/* Style de la case à cocher */
.inpt_checkbox_p {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    /* Largeur de la case à cocher */
    height: 20px;
    /* Hauteur de la case à cocher */
    border: 2px solid #ccc;
    /* Bordure de la case à cocher */
    border-radius: 3px;
    /* Rayon de la bordure */
    cursor: pointer;
    /* Curseur */
    outline: none;
    /* Supprime la bordure lorsqu'elle est cliquée */
}

/* Style de la case à cocher cochée */
.inpt_checkbox_p:checked::before {
    content: '\2713';
    /* Symbole de coche Unicode */
    display: block;
    width: 16px;
    /* Largeur de la coche */
    height: 16px;
    /* Hauteur de la coche */
    background-color: #41A62A;
    /* Couleur de fond de la coche lorsqu'elle est cochée */
    text-align: center;
    line-height: 16px;
    /* Hauteur de ligne pour centrer le symbole */
    color: #fff;
    /* Couleur du texte */
    font-size: 14px;
    /* Taille de la police */
}

/* Style de la case à cocher lorsqu'elle est survolée */
.inpt_checkbox_p:hover {
    border-color: #D9D9D9;
    /* Couleur de la bordure lors du survol */
}


.enlever_border {
    border: 1px solid #D9D9D9;
    color: black;
}

table {
    border-collapse: collapse;
    margin-top: -2.5%;
    /* border: 1px solid #D9D9D9; */

}

.tableau_liste {
    margin-top: %;

}

.p_fieldser_liste {
    /* /* margin-left: 22%; */
    width: 127%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 2% 0% 0% 0%;

}

.p_affiche_liste {
    /* position: absolute; */
    margin-top: 3%;
    margin-left: -33%;
    /* width: %; */
    color: #41A62A;
}

.nom_info {
    color: #808080;
    font-size: large;
    margin-left: 5%;
}

.field-poste-responsable {
    margin-left: 5%;
    color: #41A62A;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 3%;
}

.field-poste-equipe {
    /* width: 60%; */
    margin-left: 0%;
    color: #41A62A;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 3%;
}

/* .cadre_affiche_p_d_g {
    /* position: relative; */
/* Permet d'utiliser position:absolute dans le rectangle */
/* z-index: 3;
    background-color: white;
    margin-top: 2%; */
/* }  */

.rectangle {
    display: inline-block;
    vertical-align: top;
    /* position: relative; */
    margin-top: 8%;
    /* overflow-x: hidden; */
    /* Positionné par rapport au parent (.deuxieme-composant) */
    top: 0;
    left: 0;
    width: 102.5%;
    /**/
    /* height: 100vh; */
    /* Ajustez la hauteur selon vos besoins */
    background-color: white;
    /* Couleur du rectangle avec transparence */
    /* margin-top: 2%; */
    border-radius: 10px;
}

.cadre_affiche_info {
    /* border: 1px solid; */
    padding: 1%;
    padding-top: 3%;
    padding-left: 3%;
    width: 60%;
}

.date-selector {
    display: flex;
    align-items: center;
    margin: 3% 0 3% 0;
    /* background-color: #41A62A; */
}

.chevron-left,
.chevron-right {
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
}

.date-display {
    font-size: 18px;
    font-weight: lighter;
}

.titre_C {
    font-weight: bold;
}

.du {
    font-weight: lighter;
}

.affiche_date {
    background-color: #41A62A;
    display: flex;
    align-items: center;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 2%;
    padding: 1%;
}

.cadre_btn {
    display: flex;
    flex-wrap: wrap;
    /* Pour que les boutons passent à la ligne si l'espace est insuffisant */
    justify-content: center;
    /* Pour centrer les boutons horizontalement */
    margin-left: -1%;
}

.btn_h_p {
    flex: 1;
    /* Pour que les boutons prennent le même espace */
    min-width: 10%;
    /* Largeur maximale des boutons */
    margin: 5px;
    /* Marge autour des boutons */
    padding: 1%;
    /* Espacement à l'intérieur des boutons */
    text-align: center;
    /* Centrer le texte horizontalement */
    cursor: pointer;
    /* Curseur pointer pour indiquer qu'il s'agit d'un élément cliquable */
    border: 1px solid #D9D9D9;
    /* Supprimer la bordure */
    border-radius: 5px;
    /* Coins arrondis */
    font-size: 70%;
    background-color: white;
    color: black;
}

.cadre_fieldset {
    display: flex;
    margin-top: 2%;
}

fieldset {
    flex: 1;
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
    margin-right: 0px;
    /* Marge à droite du premier fieldset */
}

/* Pour supprimer la marge du dernier fieldset */
fieldset:last-child {
    margin-right: 0;
}
</style>