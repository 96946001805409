<template>
    <div class="g_cadre">
        <div class="etape">
            <div><img class="logoForm" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
            <div class="g_titre">
                <p>FORMULAIRE</p>
            </div>
            <div class="t_res">
                <p></p>Detail client
            </div>
            <div class="ligne1"></div>

            <div>
                <p class="d_cli">Type de réservation</p>
            </div>
            <div class="ligne2"></div>
            <div class="pay">
                <p>Payement</p>
            </div>
            <div class="rond">
                <p class="liste_1">1</p>
            </div>
            <div class="rond1">
                <p class="liste_2">2</p>
            </div>
            <div class="rond2">
                <p class="liste_3">3</p>
            </div>

        </div>
        <form @submit.prevent="save">

            <div class="tous_les_form">
                <div class="form1">
                    <div>
                        <h1>Vos information</h1>
                    </div>


                    <div class="form_d">
                        <div>
                            <fieldset>
                                <legend>Responsable</legend>
                                <input type="text" value="" v-model="Equipe.nomResponsable"
                                    placeholder="Entrer le Responsable">
                            </fieldset>
                        </div>
                        <div>
                            <fieldset>
                                <legend>Téléphone</legend>
                                <input type="text" value="" v-model="Equipe.telResponsable"
                                    placeholder="Entrer votre n° téléphone">
                            </fieldset>
                        </div>
                    </div>
                    <div class="form_g">
                        <div>
                            <fieldset>
                                <legend>Equipe</legend>
                                <input type="text" value="" v-model="Equipe.nomEquipe"
                                    placeholder="Entrer votre equipe">
                            </fieldset>
                        </div>
                        <div>
                            <fieldset>
                                <legend>Email</legend>
                                <input type="text" value="" v-model="Equipe.emailResponsable"
                                    placeholder="Entrer votre email">
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="form2">
                    <fieldset class="liste_cin">
                        <legend>Liste avec CIN</legend>
                        <div class="liste_j_c">
                            <div class="liste_j">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[0]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[1]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[2]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[3]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[4]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[5]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[6]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[7]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[8]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[9]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[10]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[11]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[12]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[13]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[14]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[15]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[16]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[17]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[18]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[19]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[20]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[21]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[22]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[23]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[24]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[25]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[26]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[27]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[28]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[29]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[30]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[31]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[32]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[33]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[34]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[35]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[36]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[37]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[38]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[39]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[40]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[41]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[42]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[43]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[44]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[45]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[46]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[47]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[48]">
                                <input type="text" id="" value="" placeholder="Entrer le nom de joueur"
                                    class="mon_input" v-model="Equipe.nomJoueurs[49]">
                            </div>
                            <div class="liste_c">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[0]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[1]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[2]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[3]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[4]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[5]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[6]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[7]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[8]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[9]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[10]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[11]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[12]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[13]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[14]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[15]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[16]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[17]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[18]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[19]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[20]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[21]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[22]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[23]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[24]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[25]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[26]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[27]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[28]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[29]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[30]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[31]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[32]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[33]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[34]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[35]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[36]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[37]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[38]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[39]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[40]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[41]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[42]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[43]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[44]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[45]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[46]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[47]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[48]">
                                <input type="text" class="mon_Input" name="" id="" v-model="Equipe.cinJoueurs[49]">
                            </div>
                        </div>
                    </fieldset>
                    <div class="div_btn_suive"><button type="submit" class="suive">Suivant</button></div>
                </div>

            </div>
        </form>
    </div>
</template>
<script>


import axios from 'axios'; // Assurez-vous que routes est correctement importé depuis votre fichier de routes

import routes from '../router'
export default {
    name: 'insertEquipe',
    data() {
        return {
            result: [],
            Equipe: {
                nomResponsable: '',
                telResponsable: '',
                emailResponsable: '',
                nomEquipe: '',
                nomJoueurs: [],
                cinJoueurs: []
            },
            formValid: false
        };
    },
    created() {
        this.loadequipeData();
    },
    methods: {
        loadequipeData() {
            const page = "https://terrain.projetphoenix.net/api/equipesB";
            axios.get(page)
                .then(response => {
                    this.result = response.data;
                })
                .catch(error => {
                    console.error('Error loading equipe data:', error);
                });
        },
        save() {
            if (this.Equipe.nomResponsable && this.Equipe.telResponsable && this.Equipe.nomEquipe && this.Equipe.emailResponsable && this.Equipe.nomJoueurs && this.Equipe.cinJoueurs) {
                this.formValid = true;
            }
            else {
                alert(" Veuiller Remplir tout les champs");
            }
            axios.post("https://terrain.projetphoenix.net/api/equipes", this.Equipe)
                .then(response => {

                    this.loadequipeData();
                    this.Equipe = { nomResponsable: '', telResponsable: '', emailResponsable: '', nomEquipe: '', nomJoueurs: [], cinJoueurs: [] }; // Correction ici
                    this.result = response.data;
                    if (response.status === 201) {

                        // Redirection vers une autre page Vue 

                    }
                    alert("le information sont sauvegarder avec succees");
                    routes.push('/Reservation'); // Correction ici

                })
                .catch(error => {
                    console.error('Error saving Client:', error);
                });

        },
        // redirectPagePrecedant() {
        //     routes.push('/Reservation');
        // }







    }
}


</script>
<style scoped>
/* Media Queries pour rendre la page responsive */
@media only screen and (max-width: 600px) {
    nav ul li {
        display: block;
        margin: 10px 0;
    }
}

/* Styles de base */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

fieldset {
    width: 70%;
    margin-left: 1%;
    border-radius: 10px;
    padding: 3%;
    font-family: Arial, Helvetica, sans-serif;
    color: #41A62A;
    margin-bottom: 5%;
    border: 1px solid #808080;
    padding-left: 10%;
    max-height: 300px;
    /* Ajustez cette valeur en fonction de la hauteur souhaitée */
    overflow-y: auto;
}

fieldset::-webkit-scrollbar {
    width: 8px;
}

fieldset::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

fieldset::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}


input {
    border: none;
    color: #808080;
    width: 90%;
}


input:focus {
    outline: none;
}


.logoForm {
    width: 50%;
    margin: 5%;
    margin-left: 22.5%;
}

h1 {
    margin-top: -2%;
}

.etape {

    background-color: #C6E4BF;
    width: 40%;
    padding-bottom: 32%;
    border-radius: 2%;
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin: 1%;
    margin-left: 1%;
}


.tous_les_form {
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin: 2%;
    width: 50%;
}

.g_titre {
    position: absolute;
    font-size: xx-large;
    font-style: bold;
    margin-left: 12.5%;
    margin-top: -2%;
}

.ligne1 {
    position: absolute;
    top: 42.5%;
    left: 0;
    width: 0.2%;
    height: 10%;
    background-color: #41A62A;
    margin-left: 11.5%;
}

.d_cli {
    position: absolute;
    margin-left: 14%;
    font-size: x-large;
    margin-top: 12.5%;
}

.t_res {


    position: absolute;
    margin-left: 14%;
    font-size: x-large;
    margin-top: 3%;
}

.ligne2 {
    position: absolute;
    top: 62.5%;
    left: 0;
    width: 0.2%;
    height: 10%;
    background-color: #41A62A;
    margin-left: 11.5%;
}

.rond {
    position: absolute;
    top: 34.5%;
    left: 0;
    width: 3%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 10%;
    border-radius: 50px;
}

.rond1 {
    position: absolute;
    top: 54.5%;
    left: 0;
    width: 3%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 10%;
    border-radius: 50px;
}

.rond2 {
    position: absolute;
    top: 74.5%;
    left: 0;
    width: 3%;
    height: 6%;
    background-color: white;
    margin-left: 10%;
    border-radius: 50px;
}

.pay {
    position: absolute;
    margin-left: 14%;
    font-size: x-large;
    margin-top: 22%;
}

.liste_1 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_2 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_3 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: #41A62A;
}




.liste_cin {
    width: 85%;
    padding-bottom: 2%;
    margin-left: 0.5%;
    padding-left: 10%;
    padding-right: 0%;
    padding-top: 0%;

}

.form_d {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}

.form_g {
    float: left;
    width: 50%;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}



.liste_j_c {
    display: flex;
}

.liste_j,
.liste_c {
    flex: 1;
    /* Pour qu'ils prennent chacun la moitié de la largeur du conteneur */
}

.liste_j,
.liste_c {
    margin-right: 20px;
    /* Marge à droite de la première div */
}

.form2 {
    padding-top: 10%;
    margin-left: 0%;
}

.btn_ajout_client {
    width: 70.5%;
    font-size: small;
    border-radius: 5px;
    border: 1px solid #808080;
    color: #808080;
    margin-left: -0.5%;
    background-color: #C6E4BF;
}

.btn_suive {
    background-color: #41A62A;
    margin-top: 0%;
    margin-left: 40%;
    padding: 1%;
    width: 20%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: large;
}

.div_btn_suive {
    margin-top: -6.5%;
    margin-bottom: 0%;
}

.suive {
    background-color: #41A62A;
    margin-left: 65%;
    padding: 1%;
    width: 20%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: large;
    margin-bottom: 0%;
}

.mon_input {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.mon_Input {
    border: 1px solid;
    border-radius: 5px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}
</style>
