<template>
    <div class="nav_bar_gauche_equipe">
        <div><img class="logo_equipe" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
        <div>
            <nav>
                <ul>
                    <li> <router-link to="/AfficheReservation"><img class="calendar_equipe"
                                src="../../assets/Icon/calendar.svg" alt="">Réservations</router-link></li>
                    <li> <router-link to="/informEquipe"><img class="check_equipe"
                                src="../../assets/Icon/file-check.svg" alt="">Informations</router-link></li>
                    <li><a class="param_equipe" href="#"><img class="seting_equipe" src="../../assets/Icon/seting.svg"
                                alt=""> Paramètre</a></li>
                    <li @click="logout"><a href="#"><img class="loug_out_equipe" src="../../assets/Icon/loug_out.svg"
                                alt="">
                            Déconnexion</a></li>
                </ul>
            </nav>
            <div v-if="loading" class="loader"></div> <!-- Afficher le loader -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            curent_token: localStorage.getItem('current_token'),
            loading: false // Variable pour suivre l'état de chargement  
        }
        

    },
    methods: {
        async logout() {
            this.loading = true; // Activer le loader
            try {
                const response = await axios.post('https://terrain.projetphoenix.net/api/logout'); // Assurez-vous que l'URL correspond à votre route backend
                delete localStorage.curent_token;
                alert(response.data.message); // Affichez le message de déconnexion
                this.$router.push('/'); // Redirigez l'utilisateur vers la page de connexion
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
                alert('Erreur lors de la déconnexion.');
            } finally {
                this.loading = false; // Désactiver le loader dans tous les cas
            }
        }
    }
};
</script>
<style lang="css" scoped>
.nav_bar_gauche_equipe {
    background-color: #C6E4BF;
    width: 20%;
    /* Largeur relative */
    border-radius: 10px;
    float: left;
    box-sizing: border-box;
    margin: 1%;
    margin-top: -1%;
    position: fixed;
    /*Position fixe */
    left: 0;
    /* Position tout à gauche */
    /* flex-shrink: 0; */
}

body {
    padding-left: 15%;
    /* Ajoutez cette ligne */
    margin-left: 0;
    /* Réinitialisez cette valeur */
    box-sizing: border-box;
    overflow-x: hidden;
}

.logo_equipe {
    width: 50%;
    /* Largeur relative */
    margin: 5%;
    margin-left: 14.5%;
    /* Marge relative */
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    margin-bottom: 10px;
}

nav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
    width: 75%;
    /* Largeur relative */
    margin-left: 10%;
    /* Marge relative */
    padding: 2%;
    padding-left: 4%;
    border-radius: 10px;
    background-color: #C6E4BF;
    font-size: 1.2em;
    /* Taille de police relative */
}

nav ul li a:hover {
    background-color: white;
}

.check_equipe,
.seting_equipe,
.loug_out_equipe,
.calendar_equipe {
    width: 10%;
    /* Largeur relative */
    margin-right: 1vw;
    /* Marge relative */
}

.param_equipe {
    margin-top: 57vh;
    /* Position verticale relative */
}

/* Media queries pour les styles responsives */
@media screen and (max-width: 768px) {
    .nav_bar_gauche {
        width: 100%;
        /* Ajustement de la largeur pour les petits écrans */
        margin: 0;
        margin-bottom: 2%;
    }

    .logo_equipe {
        width: 70%;
        /* Ajustement de la largeur pour les petits écrans */
        margin: 3%;
        margin-left: 15%;
    }

    nav ul li a {
        width: 80%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-left: 5%;
        font-size: 1em;
        /* Ajustement de la taille de la police */
    }

    .check_equipe,
    .seting_equipe,
    .loug_out_equipe,
    .calendar_equipe {
        width: 20%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-right: 2%;
    }

    .param_equipe {
        margin-top: 5vh;
        /* Ajustement de la position verticale pour les petits écrans */
    }
   .loader {
    border: 16px solid #f3f3f3; /* Couleur de fond */
    border-radius: 50%; /* Forme ronde */
    border-top: 16px solid #3498db; /* Couleur de l'animation */
    width: 120px; /* Largeur du loader */
    height: 120px; /* Hauteur du loader */
    -webkit-animation: spin 2s linear infinite; /* Animation pour les navigateurs WebKit */
    animation: spin 2s linear infinite; /* Animation pour tous les navigateurs */
    margin: 20px 0; /* Espacement vertical pour le loader */
    position: fixed; /* Fixer la position du loader */
    top: 50%; /* Centrer verticalement */
    left: 50%; /* Centrer horizontalement */
    transform: translate(-50%, -50%); /* Ajuster pour un vrai centrage */
    z-index: 1000; /* Assurez-vous que le loader est au-dessus des autres éléments */
}
/* Animation du loader */
@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



}
</style>
