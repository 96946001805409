<template>
    <div class="fond-gauche-reser">
        <div class="etape">
            <div><img class="logoForm" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
            <div class="g_titre">
                <p>FORMULAIRE</p>
            </div>
            <div class="t_res">
                <p>Detail client</p>
            </div>
            <div class="ligne1"></div>
            <div>
                <p class="d_cli">Type de réservation</p>
            </div>
            <div class="ligne2"></div>
            <div class="pay">
                <p>Payement</p>
            </div>
            <div class="rond">
                <p class="liste_1">1</p>
            </div>
            <div class="rond1">
                <p class="liste_2">2</p>
            </div>
            <div class="rond2">
                <p class="liste_3">3</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        changerStyle: {
            type: Boolean,
            default: false
        }
    }
}
</script>


<style lang="css" scoped>
/* Media Queries pour rendre la page responsive */
@media only screen and (max-width: 600px) {
    nav ul li {
        display: block;
        margin: 10px 0;
    }
}

/* Styles de base */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.fond-gauche-reser {
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    /* ou la largeur que vous souhaitez */
}

.logoForm {
    width: 50%;
    margin: 5%;
    margin-left: 23%;
}

.etape {
    /* position: fixed; */

    background-color: #C6E4BF;
    width: 100%;
    padding-bottom: 78%;
    padding-top: 3%;
    border-radius: 15px;
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin: 3%;
    margin-left: 3%;
}

.g_titre {
    position: absolute;
    font-size: xx-large;
    margin-left: 30.5%;
    margin-top: -3%;
    padding: 1%;
}

.d_cli {
    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 34%;
}

.ligne1 {
    position: absolute;
    top: 46.5%;
    left: 0;
    width: 0.4%;
    height: 10%;
    background-color: #41A62A;
    margin-left: 26%;
}

.ligne2 {
    position: absolute;
    top: 67.5%;
    left: 0;
    width: 0.4%;
    height: 10%;
    background-color: white;
    margin-left: 26%;
}

.rond {
    position: absolute;
    top: 39.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 22%;
    border-radius: 50px;
}

.rond1 {
    position: absolute;
    top: 59.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: white;
    margin-left: 22%;
    border-radius: 50px;
}

.rond2 {
    position: absolute;
    top: 79.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: white;
    margin-left: 22%;
    border-radius: 50px;
}

.pay {
    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 58%;
}

.t_res {


    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 10%;
}

.liste_1 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_2 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: #41A62A;
}

.liste_3 {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: #41A62A;
}
</style>