<template>
  <div class="date-calendar">
    <div class="calendar">
      <h1>Votre réservation</h1>
      <div class="mode-selector">
        <fieldset>
          <legend>Type de réservation</legend>
          <p class="titre-kely">Réserver par</p>
          <select class="choix" v-model="selectedMode" @change="changeMode" id="mode">
            <option disabled value="">Sélectionnez un type</option>
            <option value="day">Jour</option>
            <option value="month">Mois</option>
          </select>
        </fieldset>
      </div>
      <div class="navigation">
        <p class="titre_date_s">Date de session</p>
        <button class="btn_gauche" @click="prevMonth">&lt;</button>
        <h2>{{ currentMonthName }} {{ year }}</h2>
        <button class="btn_gauche" @click="nextMonth">&gt;</button>
      </div>
      <div class="weekdays">
        <div v-for="day in weekdays" :key="day" class="weekday">{{ day }}</div>
      </div>
      <div class="Date_Heure">
        <div class="affichage_D_H">
          <div class="days">
            <div v-for="day in daysInMonth" :key="`${day.day}-${day.month}-${day.year}`" :class="[
            'day',
            { 'highlight': isWeekend(day.day) },
            { 'selected': isSelected(day) },
            { 'empty': !day.day },
            { 'hovered': day.hovered },
            { 'reserved-date': isDateReserved(day) }
          ]" @click="toggleDate(day)" @mouseover="day.hovered = true" @mouseleave="day.hovered = false">
              {{ day.day }}
            </div>
          </div>
          <div v-if="showHours && selectedDate" class="hours">
            <h3>Heures de session :</h3>
            <button v-for="hour in availableHoursForSelectedDate(selectedDate.split('/')[0])" :key="hour"
              @click="toggleHour(hour)" :class="{ 'selected': isSelectedHour(hour) }">
              {{ hour }}
            </button>
          </div>
        </div>
        <div class="D_H_select">
          <div class="selected-dates-hours" v-if="selectedDates.length">
            <h3 class="titre_d_t_select">Date et heure sélectionnées :</h3>
            <ul>
              <li v-for="date in selectedDates" :key="date">
                <span>{{ date }}</span>
                <span v-if="selectedDateTime[date]"> || {{ selectedDateTime[date].join(', ') }}</span>
              </li>
            </ul>
            <div class="participation">
              <p><span>Participation</span> : {{ participation }} Ar</p>
            </div>
          </div>
        </div>
        <div class="btn_src">
          <div><button class="btn_suive" @click="submitReservation">Suivant</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from '../router'
import axios from 'axios';
const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
export default {
  name: 'DateCalendar',
  data() {
    const date = new Date();
    const monthIndex = date.getMonth();
    return {
      year: date.getFullYear(),
      currentMonthIndex: monthIndex,
      currentMonthName: months[monthIndex],
      weekdays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
      selectedMode: 'day',
      selectedDates: [],
      selectedDate: null,
      showHours: false,
      selectedDateTime: {},
      participation: 0,
      reservations: [],
    };
  },
  mounted() {
    this.fetchEquipeData();
    this.submitReservation();
  },
  computed: {
    reservedDates() {
      return this.reservations.map(reservation => reservation.dateReservation);
    },
    daysInMonth() {
      const lastDayOfMonth = new Date(this.year, this.currentMonthIndex + 1, 0).getDate();
      let days = [];
      const firstDayOfMonth = new Date(this.year, this.currentMonthIndex, 1).getDay();
      const startDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
      const prevMonthLastDay = new Date(this.year, this.currentMonthIndex, 0).getDate();
      const startDayPrevMonth = prevMonthLastDay - startDay + 1;

      for (let i = startDayPrevMonth; i <= prevMonthLastDay; i++) {
        days.push({ day: '', month: this.currentMonthIndex - 1, year: this.year });
      }

      for (let i = 1; i <= lastDayOfMonth; i++) {
        days.push({ day: i, month: this.currentMonthIndex, year: this.year });
      }

      const daysNeeded = 35 - days.length;
      for (let i = 1; i <= daysNeeded; i++) {
        days.push({ day: '', month: this.currentMonthIndex + 1, year: this.year });
      }

      return days;
    }
  },
  methods: {
    // isHourReserved(hour, reservation) {
    //   return reservation.includes(hour);
    // },
    isHourReserved(hour) {
      if (!this.selectedDate) return false;

      const selectedDate = this.selectedDate;
      const reservation = this.reservations.find(r => r.dateReservation === selectedDate);

      if (reservation && reservation.heureReservation.includes(hour)) {
        return true;
      }

      // Condition pour empêcher les réservations de 12h à 14h le mercredi et le vendredi
      const dateObj = new Date(this.year, this.currentMonthIndex, parseInt(selectedDate.split('/')[0]));
      const weekday = dateObj.getDay();

      if ((weekday === 3 || weekday === 5) && (hour === '12h - 14h')) {
        return true;
      }

      return false;
    },
    isHeureReservationEqualForDate(heureReservation, day, month, year) {
      return this.reservations.some(reservation => {
        return (
          reservation.dateReservation === `${day}/${month}/${year}` &&
          reservation.heureReservation === heureReservation
        );
      });
    },
    resetParticipationForDayMode() {
      if (this.selectedMode === 'day') {
        this.participation = 0;
      }
    },
    resetParticipation() {
      this.participation = 0;
    },
    calculateParticipation() {
      const selectedHoursCount = Object.values(this.selectedDateTime).flat().length;
      this.participation = selectedHoursCount * 15000;
    },
    redirectToInformEquipe() {
      routes.push('/Payement')
    },
    changeMode() {
      this.selectedDates = [];
      this.showHours = false;
      this.participation = 0;
      this.selectedDateTime = {};
    },
    isWeekend(day) {
      const date = new Date(this.year, this.currentMonthIndex, day);
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },
    prevMonth() {
      if (this.currentMonthIndex > 0) {
        this.currentMonthIndex--;
      } else {
        this.currentMonthIndex = 11;
        this.year--;
      }
      this.currentMonthName = months[this.currentMonthIndex];
    },
    nextMonth() {
      if (this.currentMonthIndex < 11) {
        this.currentMonthIndex++;
      } else {
        this.currentMonthIndex = 0;
        this.year++;
      }
      this.currentMonthName = months[this.currentMonthIndex];
    },
    toggleHour(hour) {
      const isHourSelected = this.isSelectedHour(hour);
      let selectedHoursForDate = this.selectedDateTime[this.selectedDate] || [];

      if (isHourSelected) {
        selectedHoursForDate = selectedHoursForDate.filter(h => h !== hour);
      } else {
        // Vérifier si l'heure est déjà réservée
        if (this.isHourReserved(hour)) {
          alert("Cette heure est déjà réservée.");
          return; // Ne pas ajouter l'heure sélectionnée
        }
        selectedHoursForDate.push(hour);
      }

      this.selectedDateTime[this.selectedDate] = selectedHoursForDate;
      this.updateParticipation();
    },
    toggleDate(day) {
      if (this.selectedMode === 'day') {
        this.toggleDateDayMode(day);
      } else if (this.selectedMode === 'month') {
        this.toggleDateMonthMode(day);
      }
    },
    toggleDateDayMode(day) {
      if (day.day !== '') {
        const selectedDateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
        console.log("Nouvelle date sélectionnée:", selectedDateStr);

        const isNewDateSelected = this.selectedDates.length === 0 || this.selectedDates[0] !== selectedDateStr;

        if (isNewDateSelected) {
          this.selectedDates = [selectedDateStr];
          this.showHours = true;
          this.selectedDateTime[selectedDateStr] = []; // Initialiser la sélection des heures pour cette date
          console.log("Nouvelle date sélectionnée. Affichage des heures activé.");
          this.participation = 0; // Réinitialiser la participation à zéro pour une nouvelle date
        } else {
          // La même date a été cliquée à nouveau
          console.log("La même date a été cliquée à nouveau.");
          this.selectedDates = [];
          this.showHours = false;
          this.selectedDateTime = {}; // Réinitialiser la sélection des heures
          console.log("Sélection de date réinitialisée. Affichage des heures désactivé.");
        }

        this.selectedDate = this.selectedDates[0];
        console.log("Date sélectionnée mise à jour:", this.selectedDate);
      }
    },


    toggleDateMonthMode(day) {
      if (day.day !== '') {
        const selectedDateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
        const currentDate = new Date();
        const selectedDate = new Date(this.year, this.currentMonthIndex, day.day);

        if (selectedDate < currentDate) {
          alert("Vous ne pouvez pas sélectionner une date passée.");
          return;
        }

        this.selectedHour = [];
        const previousDatesHaveHours = this.selectedDates.every(date => this.selectedDateTime[date] && this.selectedDateTime[date].length > 0);

        if (this.selectedDates.length > 0 && !previousDatesHaveHours) {
          alert('Veuillez sélectionner au moins une heure pour chaque date précédente avant de choisir une nouvelle date.');
          return;
        }

        if (this.selectedDates.length === 0) {
          this.selectedDates = [selectedDateStr];
          this.showHours = true;
        } else {
          const index = this.selectedDates.indexOf(selectedDateStr);
          if (index !== -1) {
            this.selectedDates.splice(index, 1);
            this.showHours = false;
          } else {
            this.selectedDates.push(selectedDateStr);
            this.showHours = true;
          }
        }

        this.selectedDate = selectedDateStr;
        this.updateParticipation();
      }
    },
    isSelected(day) {
      const dateStr = `${day.day}/${this.currentMonthIndex + 1}/${this.year}`;
      return this.selectedDates.includes(dateStr);
    },
    isSelectedHour(hour) {
      const selectedHoursForDate = this.selectedDateTime[this.selectedDate] || [];
      return selectedHoursForDate.includes(hour);
    },
    availableHoursForSelectedDateWithReservations(dateStr) {
      const day = parseInt(dateStr.split('/')[0]);
      const month = this.currentMonthIndex;
      const year = this.year;
      const dateObj = new Date(year, month, day);
      const weekday = dateObj.getDay();
      let availableHours = [];

      if (weekday === 6 || weekday === 0) {
        availableHours = ['6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
      } else if (weekday >= 1 && weekday <= 5) {
        availableHours = ['6h - 8h', '12h - 14h', '16h - 18h', '18h - 20h'];
      }

      return availableHours.map(hour => ({
        time: hour,
        reserved: this.isHeureReservationEqual(hour),
      }));
    },
    availableHoursForSelectedDate(day) {
      const dateObj = new Date(this.year, this.currentMonthIndex, parseInt(day));
      const weekday = dateObj.getDay();

      if (weekday === 6) {
        return [
          '6h - 8h', '8h - 10h', '10h - 12h',
          '12h - 14h', '14h - 16h',
          '16h - 18h', '18h - 20h'
        ];
      }

      if (weekday === 0) {
        return [
          '6h - 8h', '8h - 10h', '10h - 12h', '12h - 14h',
          '14h - 16h', '16h - 18h',
          '18h - 20h'
        ];
      }

      if (weekday >= 1 && weekday <= 5) {
        return ['6h - 8h', '12h - 14h', '14h - 16h', '16h - 18h', '18h - 20h'];
      }

      return [];
    },
    updateParticipation() {
      if (this.selectedMode === 'day' && this.selectedDate) {
        const selectedHours = (this.selectedDateTime[this.selectedDate] || []).length;
        this.participation = selectedHours * 15000; // Chaque heure sélectionnée coûte 15000
      } else {
        const selectedHours = Object.values(this.selectedDateTime).flat().length;
        this.participation = selectedHours * 15000; // Chaque heure sélectionnée coûte 15000
      }
      console.log('Participation mise à jour:', this.participation);
    },
    async fetchEquipeData() {
      try {
        const response = await axios.get('https://terrain.projetphoenix.net/api/equipesB');
        const data = response.data;
        this.equipe = data.equipes[0];
        this.reservations = data.reservations;
        console.log(this.reservations); // Vérifiez le format des données dans la console
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    },
    getToken() {
      // Récupère le jeton depuis le stockage local
      return localStorage.getItem('token');
    },
    isDateReserved(day) {
      const formattedDate = `${day.day}/${day.month + 1}/${day.year}`;
      return this.reservations.some(reservation => reservation.dateReservation === formattedDate);
    },
    async submitReservation() {
      try {
        // Vérifier si au moins une date est sélectionnée
        if (this.selectedDates.length === 0) {

          return;
        }

        // Formatage des dates et des heures de réservation
        const formattedDates = this.selectedDates.join(',');
        const formattedHours = {};
        this.selectedDates.forEach(date => {
          formattedHours[date] = this.selectedDateTime[date].join(',');
        });

        // Envoi de la requête à l'API
        const response = await axios.post('https://terrain.projetphoenix.net/api/reservations', {
          typeReservation: this.selectedMode,
          dateReservation: formattedDates,
          heureReservation: formattedHours,
          montanApayer: this.participation
        });

        console.log("Réponse du serveur:", response.data);
        alert('Réservation créée avec succès');
        this.redirectToInformEquipe();
      } catch (error) {
        console.error('Erreur lors de la création de la réservation:', error);
        if (error.response && error.response.data && error.response.data.error) {
          alert("Erreur de validation:\n" + JSON.stringify(error.response.data.error));
        } else {
          alert("Une erreur est survenue lors de la création de la réservation.");
        }
      }
    },
  }
}
</script>

<style lang="css" scoped>
.affichage_heure_reser {
  border: 1px solid #808080;
  width: 60%;
  padding: 1%;
  margin-right: 1%;
  border-radius: 5px;
  text-align: center;
}

.day.reserved-date {
  background-color: #C90119;
  color: white;
}


.reserved-date {
  background-color: #C90119;
  color: white;
}

.heure-reservation-match {
  background-color: #C90119;
  /* Couleur rouge pour les heures de réservation correspondantes */
  color: white;
  /* Ajoutez d'autres styles selon vos besoins */
}

.btn_src {
  display: flex;
  gap: 10px;
  margin-left: -33%;
  margin-top: 80%;
}

.btn_suive {
  display: flex;
  width: 100%;
  background-color: #41A62A;
  border: none;
  border-radius: 10px;
  color: white;
  float: left;
  margin-right: 10px;
  /* espace entre les boutons */
  /* padding: 10px 20px; */
  cursor: pointer;
  padding: 10px 20px;
  margin: 15%;
  margin-top: 60%;
}

.btn_precedent {
  display: flex;
  width: 100%;
  background-color: #41A62A;
  border: none;
  border-radius: 10px;
  color: white;
  float: left;
  margin-right: 10px;
  /* espace entre les boutons */
  /* padding: 10px 20px; */
  cursor: pointer;
  padding: 10px 20px;
  margin: 15%;
  margin-top: 49.5%;
}

.Date_Heure {
  display: flex;
}

.D_H_select {
  flex: 1;
  /* border: 1px solid; */
  /* width: 0%; */
  margin-left: 15%;
  margin-top: -12%;
  padding: 2%;
  /* background-color: #D9D9D9; */
  /* border: 1px solid #D9D9D9;; */
}

.participation span {
  color: #41A62A;
  font-weight: bolder;
}

.affichage_D_H {
  flex: 1;

}

.titre_d_t_select {
  color: #41A62A;
}

/* Styles spécifiques pour DateCalendar */
.date-calendar {
  margin-left: 42%;
  /* Marge à gauche pour laisser de l'espace pour Fond_Gauche_Reser */
  width: 60%;
  /* 100% - largeur de Fond_Gauche_Reser */
}

.hours {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-top: 20px;
}

.hours h3 {
  margin-bottom: 10px;
}

.hours button {
  /* padding: 2%;
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0.5%;
  flex-grow: 1;
  min-width: 120px; */
  padding: 2%;
  background-color: white;
  border: 1px solid;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 4px 4px 0 5px;
  /* Espacement pour séparer les boutons et du paragraphe */
  flex-grow: 1;
  width: 80px;
}

.hours button.selected {
  background-color: #C90119;
  color: white;
}

.selected-dates {
  margin-top: 20px;
}

.selected-dates ul {
  list-style-type: none;
  padding-left: 0;
}

.selected-dates li {
  margin: 5px 0;
}

.choix {
  border: none;
  margin-left: 30%;
  width: 20%;
  font-weight: bold;
}

.day {
  text-align: center;
  background-color: #D9D9D9;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.day.empty {
  background-color: transparent;
  /* fond transparent */
  color: white;
  /* texte en blanc */
}

.day.hovered {
  background-color: #C90119;
  /* couleur lors du survol */
}

.calendar {
  font-family: Arial, sans-serif;
  width: 60%;
  margin: 20px auto;
  padding: 20px;
  margin-top: 0%;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  /* background-color: white; */
  float: left;
  /* width: 50%; */
  /* Pour que chaque div prenne la moitié de la largeur */
  box-sizing: border-box;
}

.navigation {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 1%;
}

.navigation h2 {
  margin: 0;
  font-size: 1.5em;
  text-align: center;
}

.navigation button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;
  margin: 0%;
}

.navigation button:hover {
  color: #007bff;
}

.btn_gauche {
  background-color: black;
}

.weekdays {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  /* border-bottom: 1px solid #ccc; */
}

.weekday {
  flex: 0;
  text-align: center;
  font-weight: bold;
  color: #555;
  padding: 10px;
  border: none;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
  border: none;
}

.day {
  text-align: center;
  background-color: #D9D9D9;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  border: none;
}

fieldset {
  width: 68%;
  padding: 2%;
  margin: 2%;
  margin-left: 1%;
  border-radius: 5px;
  border: 1px solid #808080;
  color: #41A62A;
}

.titre_date_s {
  color: #41A62A;

}

.titre-kely {
  color: black;
  margin-left: 7%;
}

.titre-kely {
  display: inline-block;
  margin-right: 10px;
  /* Marge à droite pour séparer les éléments */
}

#mode {
  display: inline-block;
}


.day.today {
  color: #fff;
}
</style>
