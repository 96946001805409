<template>
  <div class="cadre_transaction">
    <h3>Informations</h3>
    <fieldset class="fieldset_transaction">
      <legend>Transaction du client</legend>
      <table>
        <tr>
          <td class="t_transaction_top_left">Date</td>
          <td class="t_transaction_top">Motif</td>
          <td class="t_transaction_top">Nom Résponsable</td>
          <td class="t_transaction_top">N° Téléphone</td>
          <td class="t_transaction_top">Crédit</td>
          <td class="t_transaction_top">Débit</td>
          <td class="t_transaction_top_rigth">Solde</td>
        </tr>
        <tr v-for="reservation in reservations" :key="reservation.id">
        <td>{{ reservation.dateReservation }}</td>
        <td></td>
         <td>{{ reservation.user?.responsable?.nomResponsable || 'N/A' }}</td>
         <td>{{ reservation.user?.responsable?.telResponsable || 'N/A' }}</td>
        <td></td>
        <td></td>
        <td>{{ reservation.montanApayer }}</td>
      </tr>
      </table>
    </fieldset>
    <div><button class="btn_transaction_ajouter">Ajouter</button></div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  data(){
     return{
           reservations: [],
         
          }
  },
    mounted() {
        axios.get('https://terrain.projetphoenix.net/api/ResponsableReservation')
      .then(response => {
        
        this.reservations = response.data;
        console.log('Reservations:', this.reservations); // Log reservations data
      })
      .catch(error => {
        console.error('Error fetching reservations:', error);
      });
  },
   
  

}
</script>

<style scoped>
.btn_transaction_ajouter {
  color: white;
  background-color: #41A62A;
  border: none;
  border-radius: 10px;
  margin-left: 90%;

}

.t_transaction_rigth {
  border: 1px solid #D9D9D9;
  border-right: none;
  border-bottom: none;
}

.t_transaction_top_rigth {
  border: 1px solid #D9D9D9;
  padding-top: 5%;
  border-top: none;
  border-right: none;

}

.t_transaction_left {
  border: 1px solid #D9D9D9;
  border-left: none;
  border-bottom: none;
}

.t_transaction_top_left {
  border: 1px solid #D9D9D9;
  padding-top: 5%;
  border-top: none;
  border-left: none;
}

.t_transaction_top {
  border: 1px solid #D9D9D9;
  padding-top: 5%;
  border-top: none;
  text-align: center;
}

.t_transaction {
  border: 1px solid #D9D9D9;
  padding: 2%;
  border-bottom: none;
}

table {
  border-collapse: collapse;
  width: 111%;
  margin-top: -6.5%;
  margin-left: -5.5%;
}

.fieldset_transaction {
  margin-left: 0%;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-top: 3%;
  width: 100%;
  padding-bottom: 0%;
}

.cadre_transaction {
  margin-top: 8%;
  background-color: white;
  width: 102.5%;
  border-radius: 10px;
  padding: 2%;
}
</style>