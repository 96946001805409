<template>
    <div class="fond-arrière_admin_liste">
        <Fond_gauche_admin></Fond_gauche_admin>
        <Navbar_Haut_Admin></Navbar_Haut_Admin>
        <liste_personnelle></liste_personnelle>
    </div>
</template>

<script>

import Fond_gauche_admin from './Fond_gauche_admin.vue'
import Navbar_Haut_Admin from './Navbar_Haut_Admin.vue';
import liste_personnelle from './liste_personnelle.vue'
export default {
    components: {
        Fond_gauche_admin,
        Navbar_Haut_Admin,
        liste_personnelle,
    }
}
</script>

<style lang="css" scoped>
.fond-arrière_admin_liste {
    background-color: #E7D2DF;
    position: absolute;
    width: 100%;
    /* Utilisation de % pour la largeur */
    min-height: 150vh;
    /* Utilisation de vh pour la hauteur minimale */
    box-sizing: border-box;
    /* Inclure le padding et la bordure dans la hauteur et la largeur */
    padding: 2%;
    /* Ajouter un padding pour l'espacement */
    /* Vous pouvez ajuster le padding, la marge ou la largeur en fonction de vos besoins */
    padding-left: 20%;
    /* Ajustez cette valeur pour correspondre à la largeur de votre nav_bar_gauche */
    box-sizing: border-box
}


@media screen and (max-width: 768px) {
    .fond-arrière_admin_liste {
        padding: 1%;
    }
}
</style>