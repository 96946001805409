<template>
    <div id="app">
        <fond_gauche_Poste_de_garde></fond_gauche_Poste_de_garde>
        <champInscri_Poste_de_garde></champInscri_Poste_de_garde>
    </div>
</template>

<script>
import fond_gauche_Poste_de_garde from './fond_gauche_Poste_de_garde.vue'
import champInscri_Poste_de_garde from './champInscri_Poste_de_garde.vue'

export default {
    components: {
        fond_gauche_Poste_de_garde,
        champInscri_Poste_de_garde,
    },
}
</script>

<style></style>