<template>
    <div>
        <PopupChoix></PopupChoix>
    </div>
</template>
<script>
import PopupChoix from './PopupChoix.vue'
export default {
    components: {
        PopupChoix,
    }
}
</script>

<style scoped></style>