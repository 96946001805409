<template>
    <div class=".cadre_e">
        <form @submit.prevent="register">
            <div class="c_conex">
                <h1>Inscription</h1>
            </div>
            <fieldset>
                <legend>Utilisateur</legend>
                <input type="text" v-model="user.name" placeholder="Nom d'Utilisateur">
            </fieldset>
            <fieldset>
                <legend>Votre Email</legend>
                <input type="text" v-model="user.email" placeholder="example@gmail.com">
            </fieldset>
            <fieldset>
                <legend>Mot de passe</legend>
                <input type="password" v-model="user.password" placeholder="Mot de passe ">
            </fieldset>
            <fieldset>
                <legend>Confirmation du Mot de passe</legend>
                <input type="password" v-model="user.password_confirmation" placeholder="Confirmation mot de passe">
            </fieldset>

            <div><button class="btnInscri">Se connexion</button></div>
        </form>
        <div v-if="loading" class="loader"></div> <!-- Afficher le loader -->
        <div v-if="error" class="error">{{ error }}</div>
    </div>
</template>


<script>
import axios from '../../services/axios_config';

export default {
    data() {
        return {
            user: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                typeUtilisateur: 'comptable',
            },
            error: null,
            errors: {},
            loading: false // Variable pour suivre l'état de chargement
        };
    },
     methods: {
        validateForm() {
            this.errors = {};

            if (!this.user.name) {
                this.errors.name = 'Le nom d\'utilisateur est requis.';
            }

            if (!this.user.email) {
                this.errors.email = 'L\'email est requis.';
            } else if (!this.validEmail(this.user.email)) {
                this.errors.email = 'L\'email n\'est pas valide.';
            }

            // Vérifiez l'unicité de l'email
            return this.checkEmailUnique(this.user.email)
                .then(isUnique => {
                    if (!isUnique) {
                        this.errors.email = 'L\'email est déjà utilisé.';
                    }

                    if (!this.user.password) {
                        this.errors.password = 'Le mot de passe est requis.';
                    } else if (this.user.password.length < 6) {
                        this.errors.password = 'Le mot de passe doit contenir au moins 6 caractères.';
                    }

                    if (this.user.password !== this.user.password_confirmation) {
                        this.errors.password_confirmation = 'Les mots de passe ne correspondent pas.';
                    }

                    return Object.keys(this.errors).length === 0;
                });
        },

        validEmail(email) {
            // Expression régulière simplifiée pour la validation des e-mails
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(String(email).toLowerCase());
        },
        async checkEmailUnique(email) {
            try {
                const response = await axios.get(`https://terrain.projetphoenix.net/api/check-email/${email}`);
                return response.data.isUnique; // Assume true or false
            } catch (error) {
                console.error('Erreur de vérification de l\'email:', error);
                return false;
            }
        },


        async register() {
            this.loading = true; // Activer le loader
             this.error = null; // Réinitialiser les
            if (!this.validateForm()) {
                return;
            }

            try {
                // Envoi des données utilisateur
                const response = await axios.post('https://terrain.projetphoenix.net/api/Register', this.user);

                // Vérifie si la réponse contient un jeton
                if (response.data.token) {
                    // Stockez le jeton dans localStorage
                    localStorage.setItem('token', response.data.token);

                    // Utilisation du jeton d'accès dans les en-têtes de toutes les requêtes ultérieures avec Axios
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

                    // Redirection après une inscription réussie
                    alert('Votre inscription a été réussie. Cliquez sur OK pour continuer.');
                    this.$router.push('/transaction');
                } else {
                    throw new Error('Le jeton d\'authentification est manquant dans la réponse.');
                }
            } catch (error) {
                console.error('Erreur d\'inscription:', error);
                if (error.response) {
                    if (error.response.status === 422) {
                        // Les erreurs de validation sont renvoyées avec un statut 422
                        this.errors = error.response.data.errors;
                    } else {
                        this.error = error.response.data.error || 'Une erreur s\'est produite lors de l\'inscription.';
                    }
                } else {
                    this.error = 'Une erreur s\'est produite lors de l\'inscription.';
                }
            }
            finally {
            this.loading = false; // Désactiver le loader dans tous les cas
        }
        }
    },
    created() {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }
}
</script>
<style scoped>
@import './inscri_client.css';

input {
    border: none;
    margin-left: 5%;
    font-size: medium;
    width: 90%;
}

fieldset {
    width: 20%;
    margin-left: 53%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 1%;
    font-family: Arial, Helvetica, sans-serif;
    color: #41A62A;
    margin-bottom: 2%;

}
.error {
    color: red;
    font-size: 0.875em;
}
.loader {
    border: 16px solid #f3f3f3; /* Couleur de fond */
    border-radius: 50%; /* Forme ronde */
    border-top: 16px solid #3498db; /* Couleur de l'animation */
    width: 120px; /* Largeur du loader */
    height: 120px; /* Hauteur du loader */
    -webkit-animation: spin 2s linear infinite; /* Animation pour les navigateurs WebKit */
    animation: spin 2s linear infinite; /* Animation pour tous les navigateurs */
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/*form {
    margin-left: 150%;
}*/
</style>