<template>
    <div class="fond-gauche-reser">
        <div class="etape_pay">
            <div><img class="logo_pay" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
            <div class="g_titre_pay">
                <p>FORMULAIRE</p>
            </div>
            <div>
                <p class="d_cli_pay">Type de réservation</p>
            </div>
            <div class="ligne1_pay"></div>
            <div class="t_res_pay">
                <p>Detail client</p>
            </div>
            <div class="ligne2_pay"></div>
            <div class="pay_pay">
                <p>Payement</p>
            </div>
            <div class="rond_pay">
                <p class="liste_1_pay">1</p>
            </div>
            <div class="rond1_pay">
                <p class="liste_2_pay">2</p>
            </div>
            <div class="rond2_pay">
                <p class="liste_3_pay">3</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        changerStyle: {
            type: Boolean,
            default: false
        }
    }
}
</script>


<style lang="css" scoped>
/* Media Queries pour rendre la page responsive */
@media only screen and (max-width: 600px) {
    nav ul li {
        display: block;
        margin: 10px 0;
    }
}

/* Styles de base */
body {
    font-family: 'Oxanium-Bold.ttf';
    margin: 0;
    padding: 0;
}

.fond-gauche-reser {
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    /* ou la largeur que vous souhaitez */
}

.logo_pay {
    width: 50%;
    margin: 5%;
    margin-left: 23%;
}

.etape_pay {
    /* position: fixed; */

    background-color: #C6E4BF;
    width: 100%;
    padding-bottom: 76%;
    padding-top: 3%;
    border-radius: 15px;
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
    margin: 3%;
    margin-left: 3%;
}

.g_titre_pay {
    position: absolute;
    font-size: xx-large;

    font-weight: bold;

    margin-left: 30.5%;
    margin-top: -3%;
    padding: 1%;
}

.d_cli_pay {
    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 10%;
}

.ligne1_pay {
    position: absolute;
    top: 46.5%;
    left: 0;
    width: 0.4%;
    height: 10%;
    background-color: #41A62A;
    margin-left: 26%;
}

.ligne2_pay {
    position: absolute;
    top: 67.5%;
    left: 0;
    width: 0.4%;
    height: 10%;
    background-color: #41A62A;
    margin-left: 26%;
}

.rond_pay {
    position: absolute;
    top: 39.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 22%;
    border-radius: 50px;
}

.rond1_pay {
    position: absolute;
    top: 59.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 22%;
    border-radius: 50px;
}

.rond2_pay {
    position: absolute;
    top: 79.5%;
    left: 0;
    width: 8%;
    height: 6%;
    background-color: #41A62A;
    margin-left: 22%;
    border-radius: 50px;
}

.pay_pay {
    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 58%;
}

.t_res_pay {
    position: absolute;
    margin-left: 30%;
    font-size: x-large;
    margin-top: 34%;
}

.liste_1_pay {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_2_pay {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}

.liste_3_pay {
    /* padding: 30%; */
    margin: 20%;
    font-size: x-large;
    margin-left: 34%;
    margin-top: 15%;
    color: white;
}
</style>