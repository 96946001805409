<template>
    <div class="fond_arrière_nav">
        <div class="cadre_nav">
            <div class="cadre_nav_search"><input type="search" placeholder="Entrer votre recherche"></div>
            <div class="cadre_nav_ico">
                <div class="cadre_notif"><img class="notif" src="../../assets/Icon/notification.svg" alt=""></div>
                <div class="cadre_profil"><img class="profil" src="../../assets/Icon/profil.svg" alt="">
                    <p class="nom_profil">Nom d'utilisateur</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}

</script>

<style lang="css" scoped>
.fond_arrière_nav {
    background-color: #E7D2DF;
    position: fixed;
    /* position: absolute; */
    width: 100%;
    padding-top: 6%;
    padding-bottom: 2%;
    margin-top: -5%;
    margin-left: -0.1%;

}

.notif {
    width: 7%;
    /* Largeur relative */
    margin-left: 55%;
    /* Marge relative */
    margin-top: 2%;
}

.profil {
    width: 10%;
    /* Largeur relative */
    margin-left: 7%;
    /* Marge relative */
}

.cadre_nav {
    display: flex;
    /* position: fixed; */
    justify-content: space-between;
    padding-top: 0%;
    flex-grow: 1;
    /* Permet au deuxième composant de prendre l'espace restant */
    overflow-y: auto;
    /* Permet le défilement vertical */
    max-height: 100vh;
}

.cadre_nav_search {
    margin-left: 0%;
}

.cadre_nav_ico {
    display: flex;
}

.cadre_notif {
    width: 50%;
    /* Largeur relative */
}

.cadre_profil {
    display: flex;
    align-items: center;
    border: none;
    width: 30%;
    /* Largeur relative */
    border-radius: 10px;
    margin-left: -18%;
    /* Marge relative */
    background-color: white;
}

.profil {
    margin-right: 1.5vw;
    /* Utilisation de vw pour une taille relative à la largeur de la fenêtre */
    height: auto;
    border-radius: 50%;
    border: 1px solid;
    padding: 1%;
}

.nom_profil {
    margin-left: 1vw;
    /* Utilisation de vw pour une taille relative à la largeur de la fenêtre */
    font-size: 1.2em;
    /* Utilisation de em pour une taille relative */
}

input {
    border-radius: 10px;
    border: none;
    text-align: center;
    width: 100%;
    /* Largeur relative */
    padding: 0.5em;
    /* Utilisation de em pour un padding relatif */
}

/* Media queries pour les styles responsives */
@media screen and (max-width: 768px) {

    /* Exemple de breakpoint pour un écran de taille moyenne */
    .notif,
    .profil,
    .cadre_profil,
    input {
        width: 100%;
        /* Ajustement de la largeur pour les petits écrans */
        margin-left: 0;
        /* Suppression de la marge à gauche */
    }

    .nom_profil {
        font-size: 1em;
        /* Ajustement de la taille de la police */
    }
}
</style>
