<template>
    <div class="bigContainer">
      <div class="header">
        <div class="sideLeftHeader">
          <div><img src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
          <div class="InpRecher"><input type="text" placeholder="Rechercher"></div>
        </div>
        <div class="sideRightHeader">
          
        </div>
      </div>

      <div class="sidebarMenu">
        <div class="topMenu">
          <div class="Resrv">Réservation</div>
          <div class="infrm">Informations</div>
          <div class="Ajout">Ajout utilisateurs</div>
          <div class="ListeP">Liste personelle</div>
        </div>
        <div class="bottomMenu">
          <div class="Prmtrs">Paramètres</div>
          <div class="sedeconecter">Se déconnecter</div>
        </div>
        
      </div>
      <div class="Container-White">
      <div class="conteneur_Champ">
        <div class=""></div>
        <div class="Input-Ligne1">
          <input type="text">
          <input type="text">
          <input type="text">
          <input type="text">
          <div class="contain_btn1"><button>Supprimer</button></div>
        </div>
        <div class="Input-Ligne1">
          <input type="text">
          <input type="text">
          <input type="text">
          <input type="text">
          <div class="contain_btn2"><button>Supprimer</button></div>
        </div>
        <div class="Input-Ligne1">
          <input type="text">
          <input type="text">
          <input type="text">
          <input type="text">
          <div class="contain_btn1"><button>Supprimer</button></div>
        </div>
        <div class="Input-Ligne1">
          <input type="text">
          <input type="text">
          <input type="text">
          <input type="text">
          <div class="contain_btn1"><button>Supprimer</button></div>
        </div> 
          
      </div >
          

      </div>
        
    </div>

</template>
<script>
    export default {

    }
 

</script>
<style scoped>
    @import './GestionPersonel'

</style>
  