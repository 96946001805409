<template>
    <div id="app">
        <div class="cadre_G-client">
            <div><img class="logo2" src="../../assets/image/A5_-_1-removebg-preview.png" alt=""></div>
            <div>
                <h1 class="Inscrip">S'INSCRIRE</h1>
            </div>
            <div>
                <i class="fas fa-arrow-up"></i>

                <p class="text_Inscri">Veuiller remplir les champs vides</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
@import './inscri_client.css';

/* input {
    border: none;
    text-align: center;
    font-size: medium;
} */
/* fieldset {
    width: 20%;
    margin-left: 53%;
    border-radius: 5px;
    padding: 1%;
    font-family: Arial, Helvetica, sans-serif;
    color: #41A62A;
    margin-bottom: 2%;
} */
.cadre_G-client {
    position: fixed;
    height: 672px;
    width: 50%;
    /* border: 2px solid; */
    padding-bottom: 4%;
    background-color: #868789;
    float: left;
    /* Pour que chaque div prenne la moitié de la largeur */
    box-sizing: border-box;
}
</style>